import * as msal from "@azure/msal-browser";
import Vue, {PluginObject, VueConstructor} from "vue";

declare module "vue/types/vue" {
    interface Vue {
        $msal: MsalPlugin;
    }
}

export interface MsalPluginOptions {
    clientId: string;
    loginAuthority: string;
    passwordAuthority: string;
    knownAuthority: string;
    scopes: string;
    loginUri: string;
    logoutUri: string;
}

let msalInstance: msal.PublicClientApplication;

export let msalPluginInstance: MsalPlugin;

export class MsalPlugin implements PluginObject<MsalPluginOptions> {

    private pluginOptions: MsalPluginOptions = {
        clientId: "",
        loginAuthority: "",
        passwordAuthority: "",
        knownAuthority: "",
        scopes: "",
        loginUri: "",
        logoutUri: ""
    };

    public isAuthenticated = false;


    public install(vue: VueConstructor<Vue>, options?: MsalPluginOptions): void {
        if (!options) {
            throw new Error("MsalPluginOptions must be specified");
        }
        this.pluginOptions = options;
        this.initialize(options);
        msalPluginInstance = this;
        vue.prototype.$msal = Vue.observable(msalPluginInstance);
    }

    private initialize(options: MsalPluginOptions) {
        const msalConfig: msal.Configuration = {
            auth: {
                clientId: options.clientId,
                authority: options.loginAuthority,
                knownAuthorities: [options.knownAuthority],
                redirectUri: options.loginUri,
                postLogoutRedirectUri: options.logoutUri
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case msal.LogLevel.Error:
                                console.error(message);
                                return;
                            case msal.LogLevel.Info:
                                console.info(message);
                                return;
                            case msal.LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case msal.LogLevel.Warning:
                                console.warn(message);
                                return;
                        }
                    },
                    piiLoggingEnabled: false,
                    logLevel: msal.LogLevel.Verbose
                }
            }
        };
        msalInstance = new msal.PublicClientApplication(msalConfig);
        this.isAuthenticated = this.getIsAuthenticated();
    }


    public async login() {
        try {
            const loginRequest: msal.PopupRequest = {
                scopes: ['openid'],
                prompt: 'login'
            };
            let user = this.getUser()
            if (user === null) {
                await msalInstance.handleRedirectPromise()
                const loginResponse: msal.AuthenticationResult = await msalInstance.loginPopup(loginRequest);
                this.isAuthenticated = !!loginResponse.account;
                user = this.getUser()
            }
            return new Promise((resolve) => resolve(user))

        } catch (err: Error | any) {
            console.log(err)
            this.isAuthenticated = false;
            await this.logout()
        }
    }

    public async logout() {
        const logoutRequest = {
            postLogoutRedirectUri: this.pluginOptions.logoutUri,
            mainWindowRedirectUri: this.pluginOptions.logoutUri
        }
        await msalInstance["browserStorage"].clear();
        await msalInstance.handleRedirectPromise()
        await msalInstance.logoutRedirect(logoutRequest)
        this.isAuthenticated = false;
    }

    private getIsAuthenticated(): boolean {
        const accounts: msal.AccountInfo[] = msalInstance.getAllAccounts();
        return accounts && accounts.length > 0;
    }

    public getUser() {
        const userAccounts: msal.AccountInfo[] = msalInstance.getAllAccounts()
        if (userAccounts.length <= 0) {
            return null
        } else {
            msalInstance.setActiveAccount(userAccounts[0])
            return msalInstance.getActiveAccount()
        }
    }
}