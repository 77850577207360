<template>
  <div>
    <b-input-group class="mb-3">
      <b-form-input
          v-model="displayDate"
          type="text"
          placeholder="DD/MM/YYYY"
          autocomplete="off"
          :state="isValidDate()"
          v-b-tooltip.focus title="DD/MM/YYYY"
          @blur="updateDate"
      ></b-form-input>
      <b-input-group-append>
        <b-form-datepicker
            name="selected-date"
            v-model="selectedDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            button-only
            right
            today-button
            reset-button
            label-no-date-selected=" "
            close-button
            locale="en"
            button-variant="warning"
            size="lg"
            :max="maxDate"
            @context="onContext"
        ></b-form-datepicker>
      </b-input-group-append>
    </b-input-group>
    <b-input-group>
      <b-form-input
          v-model="selectedTime"
          type="text"
          placeholder="hh:mm"
          autocomplete="off"
          :state="isValidTime()"
          v-b-tooltip.focus title="hh:mm"
      ></b-form-input>
      <b-input-group-append>
        <b-form-timepicker
            v-model="selectedTime"
            locale="en"
            button-only
            button-variant="warning"
            size="lg"
            label-no-time-selected=" "
        ></b-form-timepicker>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import {units} from "@/models/utils/constants.js";
import moment from "moment/moment";

export default {
  name: "DateTimeInput",
  props: {
    field: null,
    pastDatesOnly: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedDate: null,
      displayDate: '',
      selectedTime: null,
      selectedTz: 'Brisbane',
      tz: '+10:00',
      units: units,
      maxDate: this.pastDatesOnly ? this.tomorrow() : ''
    }
  },
  watch: {
    selectedDate() {
      const timeString = this.selectedTime ?? '00:00:00'
      const dateString = `${this.selectedDate} ${timeString} ${this.tz}`
      const dateTime = new Date(dateString.replace(/-/g, "/"))
      this.$emit('datetimeEvent', {field: this.field, value: dateTime});
    },
    selectedTime() {
      const sDate = this.selectedDate ?? new Date()
      const dateString = `${sDate} ${this.selectedTime} ${this.tz}`
      const dateTime = new Date(dateString.replace(/-/g, "/"))
      this.selectedTime = this.selectedTime.split(':').slice(0,2).join(':');
      this.$emit('datetimeEvent', {field: this.field, value: dateTime});
    },
    displayDate() {

    }
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      this.displayDate = (ctx.selectedYMD) ? moment(ctx.selectedYMD, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    isValidDate() {
      if (!this.displayDate){
        return null;
      }
      let dateToValidate = moment(this.displayDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      return dateToValidate ? this.maxDate !== '' ? this.maxDate - new Date(dateToValidate) >= 0 : null : null
    },
    isValidTime() {
      const parts = this.selectedTime ? this.selectedTime.split(':') : null
      return this.selectedTime ? parseInt(parts[0]) < 24 && parseInt(parts[1]) < 60 : null
    },
    tomorrow() {
      const today = new Date()
      return moment(today).add(1, 'days').toDate()
    },
    updateDate (ctx) {
      this.selectedDate = moment(ctx.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped>
.sr-only {
  display: none !important;
}

.sr-only.h-auto {
  height: 1px !important;
}

.input-group-append .btn-secondary {
  background-color: #ea1e1e !important;
}
</style>