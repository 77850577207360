export class Target {
    type: string | null | undefined;            // target method
    min: number | undefined;                    // default min
    max: number | undefined;                    // default max
    unit: string | null | undefined;
    range?: Array<number> | null | undefined;   // valid range
    href?: string | null | undefined;

    constructor(param: {
        type: string,
        min: number,
        max: number,
        unit: string,
        range?: [],
        href?: string
    }) {
        if (param) {
            this.type = param.type
            this.min = param.min;
            this.max = param.max;
            this.unit = param.unit;
            this.range = param.range;
            this.href = param.href;
        }
    }
}