import {Patient} from "./Patient";
import {Dose} from "./Dose";

export class PatientData {

    id?: string;
    modelId: string | undefined;
    description: string | undefined;
    patient: Patient | undefined;
    delivery: object = {};
    target: object = {};
    pathology: object = {};
    doses: Dose[] = [];
}