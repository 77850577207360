export class Pathogen {
    pathogen: string | null | undefined;
    mic: number | undefined;
    micUnit: string | null | undefined;

    constructor(param: {
        pathogen: string,
        mic: number,
        micUnit: string
    }) {
        if (param) {
            this.pathogen = param.pathogen;
            this.mic = param.mic;
            this.micUnit = param.micUnit;
        }
    }
}