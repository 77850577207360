import {ConcTime} from "./ConcTime";
import {DoseRegime} from "./DoseRegime";

let nextDosePlanId = 0;

export class DosePlan {
    id: number;
    recommendation: DoseRegime | undefined | null
    concTimeSeries: ConcTime[] = []
    _rowVariant: string | undefined | null


    constructor(recommendation: DoseRegime | undefined | null, concTimeSeries: ConcTime[], highlight: string | undefined | null) {
        // this.id = Math.round(Math.random() * 1000)
        this.id = nextDosePlanId++;
        this.recommendation = recommendation;
        this.concTimeSeries = concTimeSeries;
        this._rowVariant = highlight;
    }
}