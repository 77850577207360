import {openDB} from "idb";

const DB_NAME = 'Dosing';
const DB_VERSION = 1;
const TABLE_NAME = 'patient';

const dbPromise = openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
        db.createObjectStore(TABLE_NAME, {autoIncrement: true});
    },
});

async function get(key) {
    return (await dbPromise).get(TABLE_NAME, key);
}

async function set(key, val) {
    return (await dbPromise).put(TABLE_NAME, val, key);
}

async function remove(key) {
    return (await dbPromise).delete(TABLE_NAME, key);
}

async function keys() {
    return (await dbPromise).getAllKeys(TABLE_NAME);
}

async function getAll() {
    return (await dbPromise).getAll(TABLE_NAME);

}

export default {get, set, remove, keys, getAll}
