<template>
  <div>
    <b-card class="my-2 shadow rounded">
      <h1 class='text-md-center'>Cookie Policy</h1>
      <h3>CSIRO HeIDI Precision Dosing Calculator Application</h3>
      <span class='float-end'>
          <b-btn class='btn-outline' @click="printPage">
          <BIconPrinter/></b-btn>
        </span>
    </b-card>
    <b-card class="my-2 p-4 shadow rounded">
      <h2 class='title'>This website uses cookies</h2>
      <p>We use cookies to personalise content and to facilitate authentication. We may also use cookies to analyse
        usage of the application.</p>
      <h2 class='title'>In browser data storage</h2>
      <p>We use in-browser local data storage for personalized user convenience. Users may save their loaded data
        for later recall. The data is stored locally in the user's browser and can be deleted by clearing the
        browser data either through the application or directly through the browser settings. Note, that this data
        is only available locally and is not transmitted beyond the browser.</p>
    </b-card>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";
import FooterComponent from "@/components/Footer.vue";
import {BIconPrinter} from "bootstrap-vue";

export default {
  name: "CookiePolicy",
  components: {BIconPrinter, Title, FooterComponent},
  methods: {
    printPage() {
      window.print()
    }
  }
}
</script>

<style scoped>

</style>