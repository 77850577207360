<template>
  <b-container fluid="lg">
    <b-row>
      <b-col sm="12" md="4" lg="3">
        <Title/>
        <DrugSelect :selected-model="selectedModel" @clearPatient="update"/>
        <b-card class="my-2 shadow rounded" v-if="selectedModel">
          <PatientInput :patient-data="patientData"/>
          <TargetInput :drug="selectedDrug" :target-data="targetData"/>
          <PathologyInput :drug="selectedDrug" :pathology-data="pathologyData"/>
          <CreatinineInput :model="selectedModel" @switchTab="switchTab"/>
          <DeliveryInput :delivery-data="deliveryData"/>
          <DoseInput :model="selectedModel" @switchTab="switchTab"/>
          <MeasuredInput :model="selectedModel" @switchTab="switchTab"/>
        </b-card>
      </b-col>
      <b-col sm="12" md="8" lg="9">
        <PatientSummary @updateSavedList="update" @switchTab="switchTab"/>
        <TabPanels ref="results" :active-tab="activeTab" :update="updatePatient"/>
      </b-col>
    </b-row>
    <b-row>
      <FooterComponent/>
    </b-row>
  </b-container>
</template>

<script>

import Title from "@/components/Title.vue";
import FooterComponent from "@/components/Footer.vue";
import TabPanels from "@/components/display/TabPanels.vue";
import PatientSummary from "@/components/display/PatientSummary.vue";
import DrugSelect from "@/components/inputs/DrugSelect.vue";
import MeasuredInput from "@/components/inputs/MeasuredInput";
import DeliveryInput from "@/components/inputs/DeliveryInput";
import DoseInput from "@/components/inputs/DoseInput";
import PatientInput from "@/components/inputs/PatientInput";
import CreatinineInput from "@/components/inputs/CreatinineInput";
import PathologyInput from "@/components/inputs/PathologyInput";
import TargetInput from "@/components/inputs/TargetInput";

export default {
  name: "HomeComponent",
  data() {
    return {
      updatePatient: null,
      activeTab: 0
    }
  },
  components: {
    FooterComponent,
    TabPanels,
    PatientSummary,
    Title,
    DrugSelect,
    MeasuredInput,
    DeliveryInput,
    DoseInput,
    PatientInput,
    CreatinineInput,
    PathologyInput,
    TargetInput
  },
  computed: {
    selectedDrug() {
      return this.$store.getters.getDrug
    },
    selectedModel() {
      return this.$store.getters.getModel
    },
    patientData() {
      return this.$store.getters.getPatient
    },
    pathologyData() {
      return this.$store.getters.getPathology
    },
    targetData() {
      return this.$store.getters.getTarget
    },
    deliveryData() {
      return this.$store.getters.getDelivery
    }
  },
  methods: {
    update($evt, patientId) {
      this.updatePatient = patientId
      this.$store.dispatch('saveSelectedId', patientId)
    },
    switchTab($evt, tabId) {
      this.activeTab = tabId
    }
  }
}
</script>

<style scoped>
.row {
  display: flex;
}

.display-col {
  flex-grow: 1;
}

.input-col {
  max-width: 300px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  height: 500px;
  width: 500px;
  margin-top: 10%;
  padding: 60px 0;
  border-radius: 20px;
}
</style>
