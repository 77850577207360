<template>
  <div>
    <!-- modals -->
    <b-modal id="modal-save"
             ref="modal"
             title="Save this patient data as ..."
             @show="initModal"
             @hidden="initModal"
             @ok="savePatient"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <label>Customize a description or use default</label>
        <b-form-input
            id="name-input"
            type="text"
            v-model="description"
            minlength="5"
            :maxlength="description_max"
            required
        />
        <span class="limiter">{{ charactersLeft }}</span>
        <br/>
        <div class="form-check form-switch col-3" v-if="storedPatient">
          <input id="switch"
                 class="form-check-input"
                 type="checkbox"
                 v-model="saveNew"/>
          <label for="switch"
                 class="form-check-label">Save a copy</label>
        </div>
      </form>
    </b-modal>
    <b-modal id="modal-clear"
             ref="modal2"
             title="Clear patient data"
             @ok="clearPatient"
    >
      <div>Are you sure you want to clear all the patient data?</div>
    </b-modal>
    <!-- main content -->
    <b-card class="shadow rounded text-md-start">
      <b-container>
        <b-overlay :show="isLoading" rounded="sm" class="h-50">
          <b-row v-if="model">
            <b-col sm="12" md="3">
              <div class="text-white rounded summary-badge">{{ adult }}</div>
              <div class="my-2 p-2 rounded align-content-center text-wrap text-bg-secondary"
                   v-if="storedPatient">{{ storedPatient.label }}
              </div>
            </b-col>
            <b-col sm="12" md="6">
              <div class="summary-text overflow-auto">
                <ul>
                  <li class="text-lg text-black font-semibold">
                    {{ drug?.name }}
                    <span v-if="delivery"> &#64;
                      {{ delivery.method }}
                      <span v-if="delivery.method === 'IV'">
                        ({{ delivery.duration }} {{ displaySIUnits(delivery.durationUnit) }})
                      </span>
                </span>
                  </li>
                  <li class="text-muted font-italic" v-if="model">{{ model.name }}</li>
                  <li class="text-muted font-medium" v-if="patient?.id">
                    {{ patient.gender }} {{ patient.age }}{{ displaySIUnits(patient.ageUnit) }}
                    {{ patient.height }}{{ patient.heightUnit }}
                    {{ patient.weight }}{{ patient.weightUnit }} {{ bmi }} BMI
                  </li>
                  <li class="text-muted" v-if="pathology">MIC: {{ pathology.mic }} <span class="text-muted font-medium"
                                                                                         v-html="displaySIUnits(pathology.micUnit)"></span>
                    ({{ pathology.pathogen }})
                  </li>
                  <li class="text-muted font-medium" v-if="target">{{ target.target }}
                    {{ target.pdtMin }}-{{ target.pdtMax }} <span class="text-muted font-medium"
                                                                  v-html="displaySIUnits(target.pdtUnit)"></span>
                  </li>
                </ul>
              </div>
            </b-col>
            <b-col sm="12" md="3">
              <ul class="actions flex">
                <li v-if="ready">
                  <b-button class="btn-lg" variant="success"
                            title="Run a simulation with patient data"
                            @click="start">RUN
                  </b-button>
                </li>
                <li v-if="ready">
                  <b-button variant="outline-primary"
                            title="Save this patient data"
                            @click="saveModal">SAVE
                  </b-button>
                </li>
                <li>
                  <b-button variant="outline-secondary"
                            title="Clear patient data"
                            @click="clearData">CLEAR
                  </b-button>
                </li>
              </ul>
            </b-col>

          </b-row>
          <b-row v-else>
            <p class="alert alert-info">Please select a drug and enter patient data to start your simulation or select a
              saved patient below</p>
            <div class="text-left">
              <v-select
                  id="gender"
                  placeholder="Select a patient"
                  v-model="storedPatient"
                  class="w-80 text-left"
                  @input="loadData"
                  :options="patientList"
                  :clearable="true"
              ></v-select>
            </div>
          </b-row>
          <template #overlay>
            <div class="text-center">
              <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
              <p id="cancel-label">Please wait whilst simulation is running...</p>
            </div>
          </template>
        </b-overlay>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import {Patient} from "@/models/Patient";
import {Simulation} from "@/models/Simulation";
import {PatientData} from "@/models/PatientData";
import IDB from "@/components/services/vidb";
import {uuid} from 'vue-uuid';
import {createSamplePatient} from "@/components/services/common";
import {displaySIUnits, DoseTypes} from "@/models/utils/constants";
import {Target} from "@/models/Target";
import {Drug} from "@/models/Drug";
import {DrugModel} from "@/models/DrugModel";
import {Pathogen} from "@/models/Pathogen";
import {Delivery} from "@/models/Delivery";

export default {
  name: "PatientSummary",
  data() {
    return {
      description: null,
      description_max: 60,
      saveNew: false,
      storedPatient: null,
      error: null,
      patientList: []
    }
  },
  async created() {
    const samplePatient = createSamplePatient(null)
    await IDB.set(samplePatient.id, samplePatient)
    this.getPatientList()
  },
  computed: {
    bmi() {
      return this.patient.bmi() ?? ''
    },
    drug() {
      return this.$store.getters.getDrug;
    },
    model() {
      const drug = this.$store.getters.getModel;
      return drug ?? null;
    },
    patient() {
      return new Patient(this.$store.getters.getPatient);
    },
    adult() {
      return this.model?.population;
    },
    delivery() {
      return this.$store.getters.getDelivery;
    },
    pathology() {
      return this.$store.getters.getPathology;
    },
    target() {
      return this.$store.getters.getTarget;
    },
    selected() {
      return this.$store.getters.getSelectedId;
    },
    charactersLeft() {
      let char = this.description ? this.description.length : 0
      return (this.description_max - char) + " / " + this.description_max + " characters remaining";
    },
    ready() {
      return this.$store.getters.isReady
    },
    isLoading() {
      return this.$store.getters.getLoading
    }
  },
  methods: {
    displaySIUnits,
    populateDefaults() {
      return new Promise((resolve, reject) => {
        resolve(() => {
          // Populate any optional missing defaults
          if (!this.pathology && this.drug.pathology) {
            const defaultPathology = {...this.drug.pathology[0]}
            this.$store.dispatch('savePathology', {...defaultPathology})
          }
          if (!this.target && this.drug.target) {
            const defaultTarget = {...this.drug.target[0]}
            this.$store.dispatch('saveTarget', {
              pdtMin: defaultTarget.min,
              pdtMax: defaultTarget.max,
              pdtUnit: defaultTarget.unit,
              target: defaultTarget.type
            })
          }
          if (!this.delivery && this.model.delivery) {
            this.$store.dispatch('saveDelivery', {...this.model.delivery})
          }
        })
      })
    },
    start($event) {
      this.$store.dispatch('saveLoading', true)
      setTimeout(() => this.runSimulation($event), 30);  // timeout required to get loading to show
    },
    runSimulation($event) {
      this.$nextTick(() => {
        this.populateDefaults().then(() => {
          // Create a new simulation and run
          let sim = new Simulation()
          sim.simPatient = new Patient({...this.patient})
          sim.simDrug = new Drug({...this.drug})
          sim.simModel = new DrugModel(this.model)
          sim.simPathology = new Pathogen({...this.pathology})
          sim.simTarget = this.target?.target ? new Target({
            type: this.target.target,
            min: this.target.pdtMin,
            max: this.target.pdtMax,
            unit: this.target.pdtUnit,
            href: undefined
          }) : {...this.drug.target[0]}
          sim.simDoses = [...this.$store.getters.getDoses]
          sim.simDelivery = new Delivery({...this.delivery})
          sim.generate()
          this.$store.dispatch('saveSimulation', sim)
          this.$emit('switchTab', $event, 2)
        })
      })
    },
    async savePatient($event) {
      const patientData = new PatientData();
      if (this.storedPatient && !this.saveNew) {
        const id = this.storedPatient.code
        await IDB.remove(id)
        patientData.id = id
      } else {
        patientData.id = uuid.v1().split('-')[0]
        this.storedPatient = {code: patientData.id, label: this.description}
      }
      patientData.patient = this.patient
      patientData.modelId = this.model.code
      patientData.description = this.description
      patientData.delivery = this.delivery
      patientData.pathology = this.pathology
      patientData.target = this.target
      patientData.doses = [...this.$store.getters.getDoses]
      await IDB.set(patientData.id, patientData)
      await this.getPatientList()
      this.$emit('updateSavedList', $event, patientData.id)
      this.$toast.success(`Patient data saved: ${patientData.id}`)
    },
    initModal() {
      this.description = `${this.model.drug} ${this.patient.gender} ${this.patient.age}y`
      if (this.selected) {
        IDB.get(this.selected).then((p) => {
          const label = `[${p.id}] ${p.description}`
          this.storedPatient = {code: p.id, label}
          this.description = p.description
        })
      }
      this.saveNew = false
    },
    handleSubmit($event) {
      $event.preventDefault()
      if (this.$refs.form.checkValidity() && this.description !== null && this.description.length > 0) {
        this.savePatient($event)
      } else {
        this.error = 'Invalid entry'
      }
    },
    saveModal() {
      this.$bvModal.show('modal-save')
    },
    clearData() {
      this.$bvModal.show('modal-clear')
    },
    clearPatient($event) {
      this.$emit('updateSavedList', $event, '')
      this.storedPatient = null
      this.description = null
      this.$store.dispatch('clearAll')
      this.$emit('switchTab', $event, 1)
    },
    loadData() {
      if (this.storedPatient) {
        IDB.get(this.storedPatient.code).then((p) => {
          this.$store.dispatch('loadPatientData', p)
        })
      } else {
        this.$store.dispatch('clearAll')
      }
    },
    getPatientList() {
      IDB.getAll().then((p) => {
        this.patientList = p.map((item) => {
          const label = `[${item.id}] ${item.description}`
          return {code: item.id, label}
        })
      }).catch((e) => console.error(e))
    }
  }
}
</script>

<style scoped>
.summary-badge {
  padding: 20px;
  width: 100px;
  background-color: #2c3e50;
}

.summary-text {
  text-align: left;
  padding: 0;
  margin: 0;
  font-size: 0.9em;
}

.summary-text ul {
  list-style: none;
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  list-style: none;
}

label {
  font-weight: bold;
  float: left;
}

input {
  text-align: left;
}

.limiter {
  font-size: 0.8em;
  color: #2c3e50;
  float: right;
}

.actions {
  flex-direction: column;
}

.actions li {
  margin-right: 3px;
}

@media (max-width: 740px) {
  .actions {
    flex-direction: row;
  }
}


</style>
