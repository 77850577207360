<template>
  <b-card class="shadow rounded">
    <router-link to="/">
      <img title="HEIDI Precision Dosing Calculator"
           alt="Logo"
           src="@/assets/img/dosing_dark.png"
           :width="size" :height="size"
      /></router-link>
    <b-card-body>
      <b-card-text>Precision dosing plans based on patient data</b-card-text>
    </b-card-body>
  </b-card>
</template>

<script>

export default {
  name: "TitleComponent",
  computed: {
    size() {
      return window.innerWidth > 500 && window.innerHeight > 500 ? 200 : 100;
    }
  }
}
</script>

<style scoped>
img {
  border-radius: 100px;
}

h1 {
  font-size: 1.6rem;
}

.text-left {
  text-align: left;
}

</style>
