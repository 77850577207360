<template>
  <b-form-group class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" role="tab">
        <div v-b-toggle.creatinine-panel>CREATININE</div>
      </b-card-header>
      <b-collapse id="creatinine-panel" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <b-form @submit.prevent="saveForm">
              <InputDateValidation :key="key"
                  :field-name="fieldName"
                  :field-label="form.method"
                  :field-placeholder="fieldLabel"
                  :field-date="form.datetime"
                  :field-form="form.crcl"
                  :field-unit="form.crUnit"
                  :unit-list="units"
                  :rules-list="rules"
                  @saveInputData="loadInputData"></InputDateValidation>
              <hr/>
              <span class="flex align-content-center">
              <b-button class="rounded-circle shadow bg-secondary" title="Add serum creatinine concentration"
                        @click="saveForm"
                        :disabled="invalid || !isFormValid"><PlusIcon></PlusIcon></b-button>
            </span>
            </b-form>
          </ValidationObserver>
        </b-card-body>
      </b-collapse>
    </b-card>
  </b-form-group>
</template>

<script>
import {DoseTypes, units, ClearanceMethods, getByValue} from "@/models/utils/constants.js";
import {Dose} from "@/models/Dose";
import "@/models/utils/validations";
import InputDateValidation from "@/components/common/InputDateValidation.vue";
import PlusIcon from 'vue-material-design-icons/Plus.vue'

export default {
  name: "CreatinineInput",
  components: {InputDateValidation, PlusIcon},
  props:["model"],
  data() {
    const label = getByValue(ClearanceMethods, this.model.getClearanceMethod())[0]
    const unitList = this.getUnitList()
    return {
      form: this.initForm(),
      fieldName: "creatinine",
      fieldLabel: label,
      key: 0,
      units: unitList,
      rules: {
        secrBetween: ({
          unit: '@crUnit'
        })
      }
    }
  },
  mounted(){
    this.form = this.initForm()
  },
  methods: {
    initForm() {
      const unit = this.getUnitList()[0]
      return {
        ...this.form,
        method: this.model.getClearanceMethod(),
        crcl: null,
        crUnit: unit,
        datetime: null
      }
    },
    loadInputData(event) {
      this.form = {
        ...this.form,
        datetime: event.datetime,
        crcl: event.value,
        crUnit: event.unit
      }
    },
    getUnitList() {
      return this.model.clearance ? [this.model.clearance[0].methodUnit] :
          this.model.getClearanceMethod() === ClearanceMethods.SeCR ? units.creatinineConcentration : units.crcl
    },
    saveForm($event) {
      if (this.isFormValid) {
        this.$refs.observer.validate().then(success => {
          if (success) {
            const d = new Dose({
              type: this.form.method === ClearanceMethods.CLCR ? DoseTypes.CLCR : DoseTypes.SECR,
              datetime: new Date(this.form.datetime),
              amount: this.form.crcl,
              amountUnit: this.form.crUnit,
              duration: null,
              durationUnit: null,
              _rowVariant: 'success'
            })
            this.$store.dispatch('addDose', d);
            this.$emit('switchTab', $event, 1)
            if (this.error) {
              this.$toast.error(this.error)
              this.$store.dispatch('clearError')
            } else {
              this.$refs.observer.reset();
              this.$toast.success('Creatinine added')
            }
          }
        })
      }
    }
  },
  computed: {
    error() {
      return this.$store.getters.getError
    },
    isFormValid() {
      return this.form.datetime !== null && this.form.crcl !== null;
    }
  }
}
</script>

<style scoped>

</style>
