export const CALCULATION = {
    POPULATION: "Empiric",
    PATIENT: "Optimised"
}
export class DoseRegime {
    constructor(param: {
        calculation: string,
        interval: string,
        duration: string,
        durationUnit: string,
        ss: string,
        loading?: string,
        maintenance: string,
        concentrationUnit?: string,
        auc: string,
        peak: string,
        trough: string,
        tmic?: string,
        targetMethod?: string,
        deliveryMethod?: string
    }) {
        this.calculation = param.calculation;
        this.interval = param.interval;
        this.duration = param.duration;
        this.durationUnit = param.durationUnit;
        this.ss = param.ss;
        this.concentrationUnit = param.concentrationUnit ?? "mcg/mL";
        this.auc = param.auc;
        this.peak = param.peak;
        this.trough = param.trough;
        this.tmic = param.tmic;
        this.loading = param.loading;
        this.maintenance = param.maintenance;
        this.targetMethod = param.targetMethod ?? 'AUC/MIC';
        this.deliveryMethod = param.deliveryMethod ?? 'IV';
    }

    calculation: string | null | undefined;
    interval: string | null | undefined;
    duration: string | undefined;
    durationUnit: string | undefined;
    ss: string | undefined;
    concentrationUnit?: string | undefined;
    auc: string | undefined;
    peak: string | undefined;
    trough: string | undefined;
    tmic: string | undefined;
    loading?: string | undefined;
    maintenance: string | undefined;
    targetMethod: string | undefined;
    deliveryMethod: string | undefined;
}