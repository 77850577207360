import {ConcTime} from "../ConcTime";
import moment from "moment/moment";
import {Dose} from "../Dose";
import {getRelativeDate} from "../../components/services/common";
import {runBayesianFit} from "../../plugins/bayes";
import {PkParam} from "../PkParam";
import {Delivery} from "../Delivery";

// Two levels of debug 1 or 2 (conc levels)
const DEBUG = 1

// Steady state average
// Css = dose (mg) / (drugCl (L/h) * interval (hrs))
// @params dose: dose amount in mg
// @params cl: drug clearance in L/h
// @params interval: interval between doses in hr
// @return mg/L (mcg/ml)
export const calculateSteadyState = (dose: number, cl: number, interval: number) => {
    return dose / (cl * interval)
}

// Plasma concentration at time
// Ref: https://pharmacy.ufl.edu/files/2013/01/5127-28-equations.pdf
// @params c0: initial or starting concentration (mcg/ml)
// @params kel: elimination constant
// @params t: time of required concentration in hrs from time at c0 (ie delta time)
// @params duration: duration of infusion if c0 is initial conc else set to 0
// @returns concentration (mcg/ml)
export const calculateConcentration = (c0: number, kel: number, t: number, duration: number) => {
    return c0 * Math.exp(-kel * (t - duration))
}

// Calculate Kel from two concentrations over time
// @params conc1: first concentration (mcg/ml)
// @params conc2: second concentration (mcg/ml)
// @params dt: time difference (hr)
export const calculateKelFromConcentrations = (conc1: number, conc2: number, dt: number): number => {
    return Math.log(conc1 / conc2) / dt
}

// Calculate Peak for short-term infusion (multiple doses)
// REF: https://pharmacy.ufl.edu/files/2013/01/5127-28-equations.pdf
// EQUATION: IV Bolus Peak (multiple dose)
// @params kel: Elimination coeff
// @params interval: interval (hrs)
// @params infusion: infusion duration (hrs)
// @params drugCl: drug clearance rate (L/hr)
// @params dose: Maintenance dose amount (mg)
// @returns peak: mcg/mL

export const calculatePeak = (kel: number, interval: number, infusion: number, vd: number, dose: number) => {
    // return ((dose / vd) * Math.exp(-kel) )/ (1 - Math.exp(-kel * (interval - infusion)))
    //
    //               D          (1 - exp(-KTinfusion)
    //  Cmax  = _____________ * _____________________
    //           VKTinfusion    (1 - exp(-KTinterval)
    return (dose / (vd * kel * infusion)) * (1- Math.exp(-kel * infusion) ) / (1 - Math.exp(-kel * interval))
}

// Trough=Peak∗e(−kt)
// @params cPeak: Peak concentration
// @params kel: Elimination coeff
// @params interval: interval (hrs)
export const calculateTrough = (cPeak: number, kel: number, interval: number, infusion: number) => {
    return cPeak * Math.exp(-kel * (interval - infusion))
    // return cPeak * Math.exp(-kel * (interval))
}

// T > MIC : time spent over MIC
// Need to convert result to %T > MIC : fraction of time spent over MIC in an interval
// Ct=C0∗e(−kt)
// t = log(Ct/C0)/k
// @params cPeak: Peak concentration
// @params cMic: MIC concentration
// @params kel: Elimination coeff
// @params interval: interval (hrs)
// @params infusion: duration of infusion or 0 (hrs)
// @return time (hrs) or null
export const calculateTmic = (cPeak: number, cMic: number | undefined, kel: number, infusion: number) => {
    if (cMic) {
        // t_infusion from MIC
        let t_inf_mic = 0
        if (infusion > 0) {
            const c = Math.sqrt(infusion * infusion + cPeak * cPeak)
            const sinA = (infusion * Math.sin(90)) / c
            const tmic = (sinA * sinA * cMic) / (infusion * cPeak)
            t_inf_mic = infusion - tmic
        }
        // Decay from Peak to MIC + infusion from MIC
        return (Math.log(cPeak / cMic) / kel) + t_inf_mic
    }
    return null
}

// Calculate AUC 0-24 from peak and trough concentration.
// if mic provided, returns AUC/MIC otherwise just AUC
// LINEAR AUC - rise: AUC= ((C1+C2)/2) *(T2-T1))
// LOG AUC method - decay: AUC=((C1-C2) / (ln(C1) -ln(C2))) * (t2-t1)
//    where C1 is conc at T1 (peak for log, trough for linear) and C2 is conc at T2 (trough for log, peak for linear)
//    24 hrs = 24 * interval/(T2-T1)
// @params c_peak: peak concentration (mg/ml)
// @params c_trough: trough concentration (mg/ml)
// @params i: interval (hrs)
// @params t: duration of infusion (hrs)
// @params mic: microbial inhibitory concentration (mg/ml) - optional
// @returns auc or auc/mic (mcg*hr/mL)
export const calculateAuc = (c_peak: number, c_trough: number, i: number, t: number, mic: number | undefined) => {
    const linear_area = t * ((c_trough + c_peak) / 2);
    const decay_area = ((c_peak - c_trough) / (Math.log(c_peak) - Math.log(c_trough))) * (i - t);
    const auc_0_tau = linear_area + decay_area;
    const auc_0_24 = (auc_0_tau * 24) / i;
    return mic ? auc_0_24 / mic : auc_0_24;
}

// Calculate concentration time series
// @params interval : interval between doses (hr)
// @params vd: volume of distribution (L)
// @params kel: Elimination coeff (/hr)
// @params cl: Drug clearance (L/hr)
// @params mic: Minimum inhibitory conc (mg/L) of antimicrobial
// @params initialTime : datetime at which initialConcentration is recorded (or estimated), if c0 > 0, includes infusion time
// @params initialConcentration: Initial concentration (mcg/ml)
// @params loadingConcentration: Loading dose concentration (mcg/ml)
// @params maintenanceConcentration: Maintenance concentration (mcg/ml)
// @params infusionDuration infusion duration
// @params infusionUnit: unit for infusion duration (eg hours)
// @params calculation: Population or Patient
// @returns array of ConcTime objects for plotting
export const calculateConcTimeseries = (interval: number, label: string, vd: number, kel: number,
                                        initialTime: Date,
                                        initialConcentration: number,
                                        loadingConcentration: number | null,
                                        maintenanceConcentration: number,
                                        infusionDuration: number, infusionUnit: string,
                                        calculation: string,
                                        daysToDisplay: number = 3): ConcTime[] => {
    const doseArray: ConcTime[] = []
    let concentration: number = initialConcentration
    let infusion: number = infusionDuration ?? 1
    let infusionHrs = infusionUnit === "hours" ? infusion : infusion / 60
    const cm: number = maintenanceConcentration

    // initial point at time zero or end of administered doses
    let time = new Date(initialTime)
    const d = new ConcTime({
        interval: label,
        time: new Date(time),
        concentration,
        concentrationUnit: 'mcg/mL',
        calculation
    })
    doseArray.push(d)

    if (DEBUG > 1) {
        console.log(`${label} 0. t=${time} concentration=${concentration}`)
    }

    // First peak
    // if first dose is a loading dose
    if (initialConcentration === 0) {
        concentration = loadingConcentration ?? maintenanceConcentration
        // @ts-ignore
        time = moment(time).add(infusion, infusionUnit).toDate()
        const d = new ConcTime({
            interval: label,
            time: new Date(time),
            concentration,
            concentrationUnit: 'mcg/mL',
            calculation
        })
        doseArray.push(d)

        if (DEBUG) {
            console.log(`${label} Loading 1. t=${time}  concentration=${concentration}`)
        }
    }
    if (interval > 0) {
        let intervalStart: number = 1
        // Default 7 days for display of predicted doses
        const x = label === 'administered' ? 1 : 24 / interval * daysToDisplay
        // over x cycles, at each interval, increment dose
        for (let i: number = 0; i < x; i++) {
            const t_interval = interval - infusionHrs
            const nextDoseTime = moment(time).add(t_interval, "hours").toDate()
            if (DEBUG > 1) {
                console.log(`nextDoseTime: ${nextDoseTime} interval-inf: ${t_interval} time: ${time}`)
            }
            // calculate dose per hour
            for (let t: number = intervalStart; t < t_interval; t++) {
                time = moment(time).add(1, 'hours').toDate()
                concentration = calculateConcentration(concentration, kel, 1, 0)
                if (DEBUG > 1) {
                    console.log(`${label} Dose ${i} ${time}. concentration=${concentration}`)
                }

                const d = new ConcTime({
                    interval: label,
                    time: new Date(time),
                    concentration,
                    concentrationUnit: 'mcg/mL',
                    calculation
                })
                doseArray.push(d)
            }
            // Add trough dose
            // @ts-ignore
            const dt: number = Math.abs(nextDoseTime - time) / (1000 * 60 * 60)
            if (dt > 0) {
                concentration = calculateConcentration(concentration, kel, dt, 0)
                const d = new ConcTime({
                    interval: label,
                    time: new Date(nextDoseTime.getTime()),
                    concentration,
                    concentrationUnit: 'mcg/mL',
                    calculation
                })
                doseArray.push(d)
                if (DEBUG > 1) {
                    console.log(`${label} Trough dose diff ${i}: diff=${dt} t0=${d.time} c=${d.concentration}`)
                }
            }

            if (cm > 0) {
                // add next dose - maintenance concentration
                concentration += cm
                // @ts-ignore
                time = moment(nextDoseTime).add(infusion, infusionUnit).toDate()
                const d = new ConcTime({
                    interval: label,
                    time: new Date(time.getTime()),
                    concentration,
                    concentrationUnit: 'mcg/mL',
                    calculation
                })
                doseArray.push(d)
                if (DEBUG > 1) {
                    console.log(`${label} added next dose cm=${cm} peak t=${d.time} c=${d.concentration}`)
                }
            }
        }
    }
    if (DEBUG) {
        console.log(`${label} added ${doseArray.length} doses for ${calculation}`)
    }
    return doseArray
}

// Calculate concentration time series from one cmin to next cmin
export const calculateConcTimeseriesDC = (
        doseArray: ConcTime[],
        interval: number,
        label: string,
        vd: number,
        kel: number,
        initialTime: Date,
        initialConcentration: number,
        infusionAmount: number,
        infusionDuration: number,
        infusionUnit: string,
        calculation: string): ConcTime[] => {

    if (doseArray === undefined || doseArray.length === 0) {
        doseArray = doseArray === undefined ? []: doseArray;
        // initial point at time zero
        console.log(`Dose array empty, pushy 0 point ${label}, ${initialTime}, ${initialConcentration}`)
        doseArray.push(
            new ConcTime({
                interval: label,
                time: new Date(initialTime),
                concentration: initialConcentration,
                concentrationUnit: 'mcg/mL',
                calculation: calculation
            })
        )
    }
    let cmax = initialConcentration
    let cmaxTime = initialTime
    let infusionHrs = 0

    if (infusionAmount > 0){
        // add infusion

        let infusion: number = infusionDuration ?? 1
        infusionHrs = infusionUnit === "hours" ? infusion : infusion / 60

        // work out cmax for infusion
        cmax = (initialConcentration * Math.exp(-kel * infusionHrs)) + (((infusionAmount / infusionHrs) / (vd * kel)) * (1 - Math.exp(-kel * infusionHrs)))
        cmaxTime = moment(initialTime).add(infusionDuration, infusionUnit).toDate()
        console.log(`Pushing infusion point ${label}, ${cmaxTime}, ${cmax}`)
        doseArray.push(new ConcTime({
            interval: label,
            time: new Date(cmaxTime),
            concentration: cmax,
            concentrationUnit: 'mcg/mL',
            calculation: calculation
        }))
    }
    else {
        cmax = initialConcentration
        cmaxTime = initialTime
        infusionHrs = 0
    }

    let hoursSinceCMax = 1
    let hoursSinceStart = infusionHrs + hoursSinceCMax;
    while (hoursSinceStart <= interval){
        let concentration = cmax * Math.exp(-kel * hoursSinceCMax)
        let time = moment(initialTime).add(hoursSinceStart, "hours").toDate()
        console.log(`Pushing decay for hour ${hoursSinceCMax} - ${label}, ${time}, ${concentration}`)
        doseArray.push(new ConcTime({
            interval: label,
            time: new Date(time),
            concentration: concentration,
            concentrationUnit: 'mcg/mL',
            calculation: calculation
        }))
        hoursSinceCMax += 1
        hoursSinceStart += 1;
    }
    if (hoursSinceStart > interval && interval > infusionHrs){
        // we need to add the cmin point
        let concentration = cmax * Math.exp(-kel * (interval - infusionHrs))
        let time = moment(initialTime).add(interval, "hours").toDate()
        console.log(`Pushing decay for end of interval ${interval} - ${label}, ${time}, ${concentration}`)
        doseArray.push(new ConcTime({
            interval: label,
            time: new Date(time),
            concentration: concentration,
            concentrationUnit: 'mcg/mL',
            calculation: calculation
        }))
    }
    return doseArray
}





// Calculate exact interval between relevant doses with measured levels
// @params doses : ordered list of doses
// @params doseIdx: which dose this measured level is relevant to - start from 0
// @params next: 1 for interval to next dose, -1 for interval to previous dose
// @return interval or null
export const calculateInterval = (doses: Dose[], doseIdx: number, next: number): number | null => {
    let interval = null
    const dose = doses[doseIdx]
    try {
        if (doses.length > 1) {
            if (next > 0 && doseIdx < doses.length - next) {
                // @ts-ignore
                interval = (new Date(doses[doseIdx + next].datetime) - new Date(dose.datetime)) / (1000 * 60 * 60)
            } else if (next < 0 && doseIdx > 0) {
                // @ts-ignore
                interval = (new Date(dose.datetime) - new Date(doses[doseIdx - next].datetime)) / (1000 * 60 * 60)
            }
        }
    } catch (e: any) {
        console.log(e)
        throw new Error(e.toString())
    }
    return interval;
}
export const estimateInterval = (simDelivery: Delivery | null, modelDelivery: Delivery | null | undefined, timeFromStart: number) => {
    return simDelivery?.interval && simDelivery?.interval > timeFromStart ?
        simDelivery.interval :
        modelDelivery?.interval && modelDelivery?.interval > timeFromStart ?
            modelDelivery.interval : timeFromStart
}

// Sawchuk-Zaske method for 2 measured concns at initial or steady state (> 3 doses)
// NB: this does not use any model population values
export const calculatePkBySawchukZaske = (simDelivery: Delivery | null, modelDelivery: Delivery | null | undefined, measured: Dose[], matched: Record<number, number[]>, doses: Dose[]) => {
    let rtn = null
    // @ts-ignore
    const sawchukIntervals = Object.keys(matched).reverse().filter((k: string) => matched[k].length > 1)
    const k = sawchukIntervals[0]
    if (k) {
        console.log(`SawchukZaske method for fitting`)
        const idx = parseInt(String(k))
        // use first interval or after 3 or more doses (ss)
        if (idx === 0 || idx > 2) {
            const dose: Dose = doses[idx]
            // @ts-ignore
            const m0: Dose = measured[matched[k][0]]                        // detect first measured in interval
            // @ts-ignore
            const m1: Dose = measured[matched[k][matched[k].length - 1]]    // detect last measured in interval
            const next = 1
            // @ts-ignore
            const timeFromStart = Math.ceil((new Date(m1.datetime) - new Date(dose.datetime)) / (1000 * 60 * 60))
            const interval: number = calculateInterval(doses, idx, next) ?? estimateInterval(simDelivery, modelDelivery, timeFromStart)
            // @ts-ignore
            const dt: number = (new Date(m1.datetime) - new Date(m0.datetime)) / (1000 * 60 * 60)
            // Determine slope (Kel) from measured concentrations: ln(C0/C1) / dt
            const kel: number = calculateKelFromConcentrations(m0.amount, m1.amount, dt)
            const t_half: number = 0.693 / kel
            // For Vd, Extrapolate to Cmax and Cmin by subtracting the time of first conc from time of dose + infusion
            // Cp=Cp0 * exp(-k.t)
            const doseDate: Date = new Date(dose.datetime)
            // Find cmax
            const endInfusion = getRelativeDate(dose.duration, doseDate, false)
            // @ts-ignore
            const t1: number = (new Date(m0.datetime) - endInfusion) / (1000 * 60 * 60) //ms to hr
            const cmax: number = m0.amount / Math.exp(-kel * t1)
            // calculated trough from measured trough at time before start of next infusion
            const endInterval = getRelativeDate(interval, doseDate, false)
            // @ts-ignore
            const t2: number = (endInterval - new Date(m1.datetime)) / (1000 * 60 * 60) //ms to hr
            // If this is the first dose, set cmin to 0 (ie, not at ss)
            const cmin: number = idx === 0 ? 0 : m1.amount * Math.exp(-kel * t2)
            const vd: number = (dose.amount / dose.duration * (1 - Math.exp(-kel * dose.duration))) / (kel * (cmax - (cmin * Math.exp(-kel * dose.duration))))
            // recalculate with new vd, kel
            const cl: number = kel * vd
            if (DEBUG) {
                console.log(`SawchukZaske - patient m0: ${m0.amount} t0: ${t1} m1: ${m1.amount} t1: ${t2} dt: ${dt} cl: ${cl}ml/hr vd: ${vd} cmax: ${cmax} cmin: ${cmin}`)
            }
            rtn = {
                kel: kel.toFixed(4),
                vd: vd.toFixed(2),
                cl: cl.toFixed(2),
                half: t_half.toFixed(2)
            }
        }
    }
    return rtn
}

export const calculatePkByBayesian = (pop_clearance: PkParam, pop_vd: PkParam, simDoses: Dose[]) => {
    if (pop_clearance && pop_vd) {
        const pop_params = {
            cl: (typeof pop_clearance.value === "string") ? parseFloat(pop_clearance.value) : pop_clearance.value,
            cl_sd: (typeof pop_clearance.sd === "string") ? parseFloat(pop_clearance.sd) : pop_clearance.sd,
            vd: (typeof pop_vd.value === "string") ? parseFloat(pop_vd.value) : pop_vd.value,
            vd_sd: (typeof pop_vd.sd === "string") ? parseFloat(pop_vd.sd) : pop_vd.sd
        }
        // @ts-ignore
        const doses = simDoses.sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
        const pkparams = runBayesianFit(pop_params, doses)
        if (DEBUG) {
            console.log(pkparams)
        }
        return {
            kel: pkparams.kel_est,
            vd: pkparams.vd_est,
            cl: pkparams.cl_est,
            half: pkparams.halflife_est
        }
    }
    return null
}
