<template>
  <div class="mt-3">
    <div v-if="isAuthenticated">
      <b-container fluid="md">
        <b-row>
          <b-navbar class="flex flex-row-reverse">
            <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown right>
                <template #button-content>
                  <em>Welcome, {{ name }}</em>
                </template>
                <b-dropdown-item v-b-modal.modal-password>Password reset</b-dropdown-item>
                <b-dropdown-item @click="logout">Logout</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-navbar>
        </b-row>
      </b-container>

      <HomeComponent/>
      <b-modal id="modal-password"
               ref="modal-password"
               title="Reset your password">
        <p class="my-4">To reset your password, please logout then login again and select the 'Reset My Password' link
          in the popup.</p>
      </b-modal>
    </div>
    <div v-else>
      <b-container fluid="md" class="flex flex-column justify-content-around h-100">
        <Title/>
        <WelcomeComponent/>
        <FooterComponent/>
      </b-container>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";
import FooterComponent from "@/components/Footer.vue";
import WelcomeComponent from "@/components/Welcome.vue";
import HomeComponent from "@/components/Home.vue";

export default {
  name: "LandingComponent",
  components: {
    HomeComponent,
    WelcomeComponent,
    Title,
    FooterComponent
  },
  computed: {
    isAuthenticated() {
      return this.$msal.isAuthenticated
    },
    member() {
      return this.$store.getters.getMember ?? this.$msal.getUser()
    },
    name() {
      return this.member?.name !== 'unknown' ? this.member?.name : this.member?.idTokenClaims?.given_name ?? 'Member'
    }
  },
  methods: {
    logout() {
      return this.$msal.logout()
    }
  }
}
</script>

<style scoped>

</style>