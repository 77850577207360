import Vue from 'vue'
import App from './App.vue'
import vSelect from 'vue-select'
import "./assets/css/main.css";
import 'vue-select/dist/vue-select.css';
// import './registerServiceWorker'
import {router} from "@/plugins/router";
import VueRouter from 'vue-router';
import {BootstrapVue, IconsPlugin, VBTooltip, VBTooltipPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from "@/store";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import JsonExcel from "vue-json-excel";
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {MsalPlugin} from "@/plugins/msal-plugin";

const options = {
    position: "bottom-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
};

Vue.use(Toast, options);

Vue.config.productionTip = false
Vue.component('v-select', vSelect)
Vue.component("downloadExcel", JsonExcel);
// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VBTooltipPlugin)

export const DEBUG = import.meta.env.VITE_DEBUG ? parseInt(import.meta.env.VITE_DEBUG) : 0
export const MAILTO = import.meta.env.VITE_ADMIN_EMAIL
export const API_URL = import.meta.env.VITE_API_URL ?? `${import.meta.env.BASE_URL}/api`
console.log(`Running API on ${API_URL}`)
export const VERSION = import.meta.env.VITE_APP_VERSION
console.log(`Loading version ${VERSION}`)
export const JWT_CODE = import.meta.env.VITE_JWT_CODE
export const AZURE_TENANT = import.meta.env.VITE_AZURE_TENANT
export const AZURE_AUTHORITY = import.meta.env.VITE_AZURE_AUTHORITY
export const AZURE_PASSWORD = import.meta.env.VITE_AZURE_PASSWORD_RESET_AUTHORITY
export const AZURE_KNOWN = import.meta.env.VITE_AZURE_KNOWN
export const AZURE_CLIENT_ID = import.meta.env.VITE_AZURE_CLIENT_ID
export const AZURE_SCOPES = import.meta.env.VITE_AZURE_SCOPES
export const AZURE_REDIRECT_LOGIN = import.meta.env.VITE_AZURE_REDIRECT_LOGIN ?? import.meta.env.BASE_URL
export const AZURE_REDIRECT_LOGOUT = import.meta.env.VITE_AZURE_REDIRECT_LOGOUT ?? import.meta.env.BASE_URL
const msal_options = {
    clientId: AZURE_CLIENT_ID,
    loginAuthority:  AZURE_AUTHORITY,
    passwordAuthority: AZURE_PASSWORD,
    knownAuthority: AZURE_KNOWN,
    scopes: AZURE_SCOPES,
    loginUri: AZURE_REDIRECT_LOGIN,
    logoutUri: AZURE_REDIRECT_LOGOUT
}

Vue.use(new MsalPlugin(), msal_options)

Vue.use(VueRouter);

new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app')
