<template>
  <div>
    <b-card class="my-2 shadow rounded">
      <h1 class='text-md-center'>Terms of Use</h1>
      <h3>CSIRO HeIDI Precision Dosing Calculator Application</h3>
      <span class='float-end'>
          <b-btn class='btn-outline' @click="printPage">
          <BIconPrinter/></b-btn>
        </span>
    </b-card>
    <b-card class="my-2 p-4 shadow rounded">
      <p>By using the App and the Content available on and through it, you are agreeing to be
        bound by these Terms of Use and the <a class='link link-hover'
                                               href='https://www.csiro.au/en/about/Policies/Privacy'>CSIRO Privacy
          Policy
          <BIconBoxArrowUpRight/>
        </a>.
      </p>
      <p>The Terms of Use form the legally binding agreement between you and Commonwealth Scientific and Industrial
        Research Organisation ABN 41 687 119 230 (CSIRO). We may amend these Terms of Use at any time. Amendments to
        these Terms of Use will be effective immediately upon notification on this App. Your continued use of the
        App
        following such notification will represent an agreement by you to be bound by the Terms of Use as amended.
      </p>
      <p>
        <b>Important message:</b> The results and analyses contained in this App are based
        on
        historic data collected by CSIRO
        and third party data providers and are intended for use by persons with technical skill and knowledge in
        clinical medicine, and pharmaceutical dosing. No reliance or actions must be made on that
        information alone and the user must make their own assessment of the suitability for its use of the
        information
        or material contained in or generated from the App, in the context of their own clinical judgement and
        expertise
        and within applicable therapeutic guidelines. To the extent permitted by law, CSIRO (including its employees
        and
        consultants) and all third party data providers exclude all liability to any person for any consequences,
        including but not limited to all losses, damages, costs, expenses and any other compensation, arising
        directly
        or indirectly from using this App (in part or in whole) and any information or material contained in it.
      </p>

      <h2 class='title'>Terminology</h2>
      <ul class="align-start">
        <li><span class='font-bold'>Application or App</span> – means the HeIDI Precision Dosing Calculator
          application.
        </li>
        <li><span class='font-bold'>App Content</span> – means Content you access via the App.</li>
        <li><span class='font-bold'>Content</span> – means content or material, and includes, but is not limited to:
          photos, graphs, other images, music; textual material such as reviews, annotations, comments, forum and
          blog
          postings, information; geospatial/location data, records, metadata; databases, datasets and models.
        </li>
        <li><span class='font-bold'>You/user</span> – means you as a user of the Application or App Content.</li>
      </ul>
    </b-card>
    <b-card class="my-2 shadow rounded">

      <h2 class='title'>Registration and Termination</h2>
      <p>In order to use the App, you must be a registered user and establish a user
        account which includes your name, password and email or link an existing account from one of the available
        identity providers.
        Only you may login to, and subsequently use, your user account. You are responsible for maintaining the
        confidentiality and security of your login details (e.g. username and password and/or any account
        information
        for the App, as well as the security of all device(s) through which you access the App. You are therefore
        responsible for everything done or submitted using your user account. If you wish to terminate your
        registration
        or user account, please contact us.
        We may lock, restrict, suspend and/or terminate your registration or user account if you breach these Terms
        of
        Use, or if we have reasonable grounds to suspect this is the case.
      </p>
      <h2 class='title'>Acknowledgements</h2>
      <p>This website is owned and operated by CSIRO. The App (including any images and text incorporated therein)
        contains proprietary information and copyright owned by CSIRO, with the following acknowledgements:
        the Queensland Government Metro North Herston Infectious Diseases Institute (HeIDI) sponsored and
        contributed
        intellectual property to the development of the App.
      </p>
      <h2 class='title'>Personal Information</h2>
      <p>This website may require cookies to be enable it to function. Consequently, if you do not allow CSIRO to
        use
        cookies, you may not be able to use the website or the App.
        A cookie is a text string that is included with Hypertext Transfer Protocol (HTTP) requests and responses.
        Cookies are used to maintain certain information as you navigate different pages on a website or return to
        the
        website at a later time.
        If used, the function of cookies in the website would be to enable a seamless process for you. CSIRO does
        not
        use cookies to track your use of the website.
        The App is hosted by CSIRO on Google Cloud Platform (GCP) servers based in Australia and authentication
        services
        are provided by Microsoft Azure Active Directory B2C. CSIRO may disclose your information to third parties
        including Google Cloud and Azure for this purpose.
        Any personal information collected by CSIRO (including your name, login details, information relating to
        your
        use of the App e.g. Internet Protocol (IP) address and log/usage data and any other information collected
        from
        your registration) during your visit to the website and use of this App will be dealt with in accordance
        with
        CSIRO's Privacy Statement, which can be found here: Privacy Policy, which also forms part of the Terms of
        Use.
        For information about how Google Cloud and Microsoft generally handles personal information, please refer to
        their general privacy policies available at https://cloud.google.com/terms/cloud-privacy-notice/ and
        https://privacy.microsoft.com/en-us/privacystatement.
      </p>
      <h2 class='title'>Using the App and App Content</h2>
      <p>You may use the App and App Content only for lawful purposes, in a manner that does not infringe the rights
        of,
        or restrict or inhibit the use and enjoyment of, the App by any third party.
      </p>

      <ul class="align-start">When you use the App or App Content, you must not post or transmit material which:
        <li>is obscene, offensive, unlawful, defamatory, malicious, threatening, abusive, or scandalous;</li>
        <li>violates any law, including laws regarding harassment, discrimination, racial vilification, privacy or
          contempt;
        </li>
        <li>encourages conduct that would contravene any law;</li>
        <li>is intentionally false or misleading; or</li>
        <li>is an infringement of copyright or other intellectual property rights.</li>
      </ul>

      <ul class="align-start">If you use or download Content from the App, you:
        <li>recognise that it may be protected by copyright and other intellectual property rights (including moral
          rights) and will respect those rights;
        </li>
        <li>agree to acknowledge, reference or attribute CSIRO and the original authors of the Content (if
          specified) in
          any derived information product, work or publication that is based on the relevant Content;
        </li>
        <li>will not remove any identifier of ownership, disclaimer or terms of use that are associated with the
          Content;
        </li>
        <li>may distribute Content to third parties under the terms of these Terms of Use. You must not impose any
          additional restrictions on the use of original, unaltered Content by third parties; and
        </li>
        <li>recognise that the Content is provided without any warranty regarding its quality, accuracy,
          completeness,
          currency, relevance or suitability for any particular purpose and that you use the Content at your own
          risk
          (see ‘Disclaimer’ below).
        </li>
      </ul>


      <h2 class='title'>Intellectual Property</h2>
      <p>All intellectual property rights in this App, including design, text, graphics, video, logos, icons, sound
        recordings and all software relating to this App, belong to or are licensed to CSIRO. These intellectual
        property rights are protected by Australian and international laws.
        You may not in any form or by any means copy, adapt, reproduce (other than for the purpose of viewing the
        App in
        your browser), store, modify, distribute, print, upload, display, perform, remove any credits, publish,
        post,
        frame within another website or application, or create derivative works from any part of this App or
        commercialise any Content obtained from any part of this App without our prior written permission or, in the
        case of third party material, from the owner of the intellectual property rights in that material.
        Many of the trademarks or logos displayed on the App such as the App logo, CSIRO logo or name are
        trademarks of CSIRO and cannot be used in a manner that suggests CSIRO endorses or is associated with your
        business, products or services without the express written permission of CSIRO.
      </p>
      <h2 class='title'>Disclaimer</h2>
      <p>The App, linked websites and App Content are intended to provide information for use by persons with
        clinical pharmaceutical training and knowledge of the
        management and interpretation of pharmaceutical dosing regimens.
        CSIRO makes the App and App Content available on the understanding that you use them at your own risk – they
        are
        provided ‘as is’ and ‘as available’ and you exercise your own skill, judgement and care with respect to
        their
        use or your reliance on them (including anything you access through links to external third party
        websites). </p>
      <ul class="align-start">You acknowledge that Content on or made available via the App:
        <li>may contain statements based on scientific research and comprise data collected by CSIRO and third
          parties;
        </li>
        <li>is not professional, scientific, medical, technical or expert advice and may be subject to the usual
          uncertainties of advanced scientific and technical research; and
        </li>
        <li>should not be relied upon as specific to you and therefore as the basis for doing or failing to do
          something.
        </li>
      </ul>
      <p>
        CSIRO (and its third party data providers) give no warranty regarding the quality, accuracy, completeness,
        currency, relevance or suitability for any particular purpose of the App Content or the App. No reliance or
        actions must be made on the App Content or the App without seeking prior expert professional, scientific and
        technical advice.
        To the fullest extent permitted by applicable law, CSIRO (including its employees and contractors) and its
        third
        party data providers exclude all liability to any person for any consequences, including but not limited to
        all
        losses, damages (including indirect, special or consequential damages, loss of business, revenue/profit,
        loss of
        time etc.), costs, expenses and any other compensation, arising directly or indirectly from your use of the
        App
        or App Content (including through links to external third party websites) or inability to access the App.
      </p>
      <h2 class='title'>Links to External Websites</h2>
      <p>The App may include links to external websites; however, we do not have direct control over the Content of
        the
        linked websites, nor the changes that may occur to Content on those websites. We are not responsible for the
        Content or privacy practices associated with linked websites and applications.
        When you click the link to an external website, you will be redirected to a third party site.
        You should consult the legal and copyright notices on the third party website to determine the extent to
        which
        you may (or may not) be able to use Content on that website for your intended purpose. We make no
        representation
        that the material on any linked websites does not infringe the intellectual property rights or any other
        rights
        of any person. Nor do we authorise the reproduction of such material.
        Links to external websites do not constitute an endorsement by CSIRO or a recommendation by CSIRO of those
        host
        organisations or websites, including any information, material or third party products or services available
        from or through those websites.
      </p>
      <h2 class='title'>Linking to the App</h2>
      <p>If you choose to link the App to your own external website, such links should not suggest that your
        website,
        organisation or services/products are endorsed by CSIRO. CSIRO reserves the right to prevent linking or
        framing
        if these conditions are not met.
      </p>
      <h2 class='title'>Security</h2>
      <p>The internet exists across insecure, public networks and there is a risk of unauthorised access to the
        Content;
        that transmissions to or from this App may be intercepted and modified by third parties; or that files
        obtained
        from or through this App may contain computer viruses, disabling codes, worms or other defects.
        CSIRO accepts no liability for any interference with or damage to your computer system, software, or data
        occurring in connection with or relating to the App or its use including the tools provided. You are
        encouraged
        to take appropriate and adequate precautions to ensure that whatever is obtained from this App is free of
        viruses or other contamination that may interfere with or damage your computer system, software or data. We
        recommend that you use appropriate and up-to-date firewall and anti-virus software to protect your computer
        systems.
        You must not try to circumvent the security features of the App, or tamper with, hack into, or in some other
        way
        disrupt any computer system, server, website, router or other device used to host this App or make it
        available.
      </p>
      <h2 class='title'>Email Addresses on the App</h2>
      <p>The presence of email addresses on the App does not infer consent to send unsolicited commercial electronic
        messages (email) to those addresses.
      </p>
      <h2 class='title'>General</h2>
      <p>These Terms of Use are governed by the law applicable to the State of New South Wales and the parties
        submit to
        the non-exclusive jurisdiction of the courts of that State.
        No delay, neglect or forbearance on the part of CSIRO in enforcing these Terms of Use will be, or deemed to
        be,
        a waiver. Nor will it prejudice any rights of CSIRO.
      </p>
    </b-card>
  </div>
</template>
<script>
import {BIconPrinter, BIconBoxArrowUpRight} from "bootstrap-vue";
import Title from "@/components/Title.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  name: 'TermsOfUse',
  components: {BIconBoxArrowUpRight, BIconPrinter, Title, FooterComponent},
  methods: {
    printPage() {
      window.print()
    }
  }
}
</script>

<style>
.align-start, .align-start li {
  text-align: start;
}

</style>
