import VueRouter from "vue-router";
import LandingComponent from "@/components/Landing.vue";
import HomeComponent from "@/components/Home.vue";
import TermsOfUse from "@/components/legal/TermsOfUse.vue";
import CookiePolicy from "@/components/legal/CookiePolicy.vue";
import LegalComponent from "@/components/Legal.vue";
import {msalPluginInstance} from "@/plugins/msal-plugin";

const routes = [
    {path: '/', name: 'landing', component: LandingComponent},
    {path: '/home', name: 'home', component: HomeComponent},
    {
        path: '/legal', component: LegalComponent,
        children: [
            {path: '/terms', component: TermsOfUse},
            {path: '/cookies', component: CookiePolicy},
        ]
    }
]

export const router = new VueRouter({
    mode: 'history',
    routes,
})

router.beforeEach((to, from, next) => {
    const msal = msalPluginInstance;
    if (to.name === 'home' && !msal.isAuthenticated) next({ name: 'landing' })
    else next();
});