<template>
  <b-form-group class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" role="tab">
        <div v-b-toggle.measured-panel>MEASURED DRUG CONC</div>
      </b-card-header>
      <b-collapse id="measured-panel" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <b-form @submit.prevent="addMeasured">
              <p class="text-lg text-black font-semibold">Enter date and time for measured concentration</p>
              <InputDateValidation
                  :field-name="fieldName"
                  field-title="Conc."
                  field-placeholder="Measured concentration"
                  :field-date="form.datetime"
                  :field-form="form.amount"
                  :field-unit="form.amountUnit"
                  :unit-list="units"
                  :rules-list="rules"
                  @saveInputData="loadInputData"></InputDateValidation>
              <hr/>
              <span class="flex flex-column align-content-center">
              <b-button class="rounded-circle shadow bg-secondary" title="Add measured concentration"
                        @click="addMeasured" :disabled="invalid || !isFormValid"><PlusIcon></PlusIcon></b-button>
            </span>
            </b-form>
          </ValidationObserver>
        </b-card-body>
      </b-collapse>
    </b-card>
  </b-form-group>
</template>

<script>
import {DoseTypes, units} from "@/models/utils/constants.js";
import {Dose} from "@/models/Dose";
import InputDateValidation from "@/components/common/InputDateValidation.vue";
import PlusIcon from 'vue-material-design-icons/Plus.vue'

export default {
  name: "MeasuredInput",
  components: {InputDateValidation, PlusIcon},
  data() {
    return {
      form: this.initForm(),
      fieldName: "measured",
      units: units.concentration,
      rules: {
        between: [0.1, 10000]
      }
    }
  },
  methods: {
    initForm() {
      return {
        ...this.form,
        amount: null,
        amountUnit: 'mg/L',
        datetime: null
      }
    },
    loadInputData(event) {
      this.form = {
        ...this.form,
        datetime: event.datetime,
        amount: event.value,
        amountUnit: event.unit
      }
    },
    addMeasured($event) {
      if (this.isFormValid) {
        this.$refs.observer.validate().then(success => {
          if (success) {
            const dose = new Dose({
              type: DoseTypes.CONC,
              datetime: new Date(this.form.datetime),
              amount: this.form.amount,
              amountUnit: this.form.amountUnit,
              duration: null,
              durationUnit: null,
              _rowVariant: 'info'
            });
            this.$store.dispatch('addDose', dose)
            this.$emit('switchTab', $event, 1)
            if (this.error) {
              this.$toast.error(this.error)
              this.$store.dispatch('clearError')
            } else {
              this.$toast.success('Measured concentrations added')
            }
          }
        })
      }
    }
  },
  computed: {
    error() {
      return this.$store.getters.getError
    },
    isFormValid() {
      return this.form.datetime !== null && this.form.amount !== null;
    },
  }
}
</script>

<style scoped>

</style>
