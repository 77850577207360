//** Common functions **/
// Creates a time series of doses from user input
import {Dose} from "@/models/Dose";
import {Alerts, DoseTypes} from "@/models/utils/constants";
import {samplePatient, samplePatient2Compartment} from "@/models/utils/defaults";
import moment from "moment/moment";
import {Simulation} from "@/models/Simulation";
import {calculateCockcroftGault} from "@/models/utils/clearance";
import {Drug} from "@/models/Drug";
import {DrugModel} from "@/models/DrugModel";
import {Delivery} from "@/models/Delivery";
import {Target} from "@/models/Target";
import {Pathogen} from "@/models/Pathogen";

export const DATEFORMAT = {
    DMY: "DD/MM/YYYY hh:mm",
    YMD: "YYYY-MM-DD hh:mm",
    YMDA: "YYYY-MM-DD hh:mm A",
    UTC: "YYYY-MM-DDThh:mm:ssZ",
    NA: "Not applicable"
}

export const FILESOURCE = {
    DOSING: "Heidi Dosing Calculator",
    IDODS: "ID-ODS shiny app",
    PMETRICS: "PMetrics Data - single patient"
}

export const ERROR = {
    INVALID: "Data is invalid"
}

export const createDoseTimeSeries = (initItem, item, number, incrementValue, incrementType) => {
    let list = initItem.amount ? [initItem] : [];
    let startDate = initItem.datetime ? new Date(initItem.datetime) : new Date();

    for (let i = 0; i < number; i++) {
        if (list.length > 0) {
            if (incrementType === 'hours') {
                startDate.setHours(startDate.getHours() + parseInt(incrementValue));
            } else if (incrementType === 'days') {
                startDate.setDate(startDate.getDate() + parseInt(incrementValue));
            }
        }
        list.push({...item, id: item.id + i, datetime: new Date(startDate)})
    }
    return list;
}

// Expects whole hours
export const getRelativeDate = (hours, startDate, back = true) => {
    const days = Math.floor(hours / 24)
    const hrs = Math.floor(hours % 24)
    const previous = startDate ? new Date(startDate) : new Date()
    if (days > 0) {
        if (back === true) {
            previous.setDate(previous.getDate() - days)
        } else {
            previous.setDate(previous.getDate() + days)
        }
    }
    if (hrs > 0) {
        if (back === true) {
            previous.setHours(previous.getHours() - hrs)
        } else {
            previous.setHours(previous.getHours() + hrs)
        }
    }
    return previous
}
// Import date and time from CSV data
// @param importedTime: date and time as one string
// @param formatType: datetime format
export const convertDateTime = (importedTime, formatType) => {
    const d = moment(importedTime, formatType).toDate()
    if (d instanceof Date && !isNaN(d)) {
        return d
    } else {
        return ERROR.INVALID
    }

}

export const importDoseFromRow = (sourceFormat, dateFormat, row, start) => {
    let dose = null
    if (sourceFormat === 'IDODS') {
        const type = row.Type.indexOf('Dosing') === 0 ? DoseTypes.DOSE :
            row.Type === 'Concentration' ? DoseTypes.CONC :
                row.Type === 'Creatinine' ? DoseTypes.SECR : null;

        if (type) {
            const datetime = convertDateTime(row.Time, DATEFORMAT[dateFormat])
            if (datetime !== ERROR.INVALID) {
                if (type === DoseTypes.DOSE) {
                    dose = new Dose({
                        type,
                        datetime,
                        amount: parseFloat(row.Value),
                        amountUnit: 'mg',
                        duration: parseFloat(row.Infusion),
                        durationUnit: 'hours'
                    })
                } else if (type === DoseTypes.CONC) {
                    dose = new Dose({
                        type,
                        datetime,
                        amount: parseFloat(row.Value),
                        amountUnit: 'mg/L',
                        _rowVariant: 'info'
                    })
                } else if (type === DoseTypes.SECR) {
                    dose = new Dose({
                        type,
                        datetime,
                        amount: parseFloat(row.Value),
                        amountUnit: 'umol/L',
                        _rowVariant: 'success'
                    })
                }
            } else {
                dose = ERROR.INVALID
            }
        }
    } else if (sourceFormat === 'DOSING') {
        const datetime = convertDateTime(row.Time, DATEFORMAT[dateFormat])
        if (datetime !== ERROR.INVALID) {
            if (row.Type === DoseTypes.DOSE) {
                dose = new Dose({
                    type: row.Type,
                    datetime,
                    amount: parseFloat(row.Value),
                    amountUnit: row.Value_unit,
                    duration: parseFloat(row.Infusion),
                    durationUnit: row.Infusion_unit
                })
            } else if (row.Type === DoseTypes.CONC) {
                dose = new Dose({
                    type: row.Type,
                    datetime,
                    amount: parseFloat(row.Value),
                    amountUnit: row.Value_unit,
                    _rowVariant: 'info'
                })
            } else if (row.Type === DoseTypes.SECR) {
                dose = new Dose({
                    type: row.Type,
                    datetime,
                    amount: parseFloat(row.Value),
                    amountUnit: row.Value_unit,
                    _rowVariant: 'success'
                })
            }
        } else {
            dose = ERROR.INVALID
        }
    } else if (sourceFormat === 'PMETRICS') {
        const datetime = moment(start).add(parseFloat(row.TIME), 'hours').toDate()
        if (parseInt(row.EVID) === 1) {
            dose = new Dose({
                type: DoseTypes.DOSE,
                datetime,
                amount: parseFloat(row.DOSE),
                amountUnit: 'mg',
                duration: parseFloat(row.DUR),
                durationUnit: 'hours'
            })
        } else if (parseInt(row.EVID) === 0) {
            dose = new Dose({
                type: DoseTypes.CONC,
                datetime,
                amount: parseFloat(row.OUT),
                amountUnit: 'mg/L',
                _rowVariant: 'info'
            })
        }
    }
    return dose
}
// Remove duplicate doses based on time and type
export const deduplicateDoses = (doses) => {
    let uniqueList = [];
    if (doses.length > 0) {
        const typelist = doses.filter((t, i, a) => a.findIndex(t2 => (t2.type === t.type)) === i).map((m) => m.type)
        typelist.forEach((dosetype) => {
            const doselist = doses.filter((d) => d.type === dosetype)
            const dosesfiltered = doselist.filter((d, index, ary) => {
                return ary.findIndex(d2 => (d2.datetime.toString() === d.datetime.toString())) === index
            })
            uniqueList = uniqueList.concat(dosesfiltered)
        })
    }
    return uniqueList
}

export const calculateSince = (doses) => {
    const sortedDoses = doses.sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
    let first = sortedDoses.filter((d) => d.type === DoseTypes.DOSE)[0] ?? null
    /** validation limits **/
    const maxInterval = 24 * 7
    const maxWeekHrs = 24 * 7
    if (first) {
        const duration = first.durationUnit === 'hours' ? first.duration * 1 : first.duration / 60
        doses = sortedDoses.map((e, index, ary) => {
            if (e.id !== first.id) {
                const dt = (e.datetime.getTime() - first.datetime.getTime()) / (1000 * 60 * 60)
                e.since = dt.toFixed(2) // hrs
                e.sinceAlert = null
                if (e.type === DoseTypes.DOSE) {
                    e.sinceAlert = e.since < 1 + duration ? Alerts.SINCE_INFUSION : e.since > maxInterval ? Alerts.SINCE_MAX_WEEK : null
                    first = e
                } else if (e.type === DoseTypes.SECR) {
                    e.sinceAlert = e.since < -maxWeekHrs ? Alerts.SINCE_MAX_WEEK : e.since > maxWeekHrs ? Alerts.SINCE_MAX_WEEK : null
                } else if (e.type === DoseTypes.CONC) {
                    e.sinceAlert = e.since < 0 ? Alerts.SINCE_BEFORE :
                        e.since < 1 + duration ? Alerts.SINCE_INFUSION :
                            e.since > maxWeekHrs ? Alerts.SINCE_MAX_WEEK : null
                }
            } else {
                e.since = null
                e.sinceAlert = null
            }
            return e
        })
    }
    return doses
}

// Create a patient for testing using current date or provided date
// Using Vancomycin estimates
export const createSamplePatient = (startDate) => {
    // set samplePatient doses to current date
    const testStartDate = startDate ?? getRelativeDate(24, null)
    const updatedDoses = [new Dose({
        type: DoseTypes.SECR,
        datetime: getRelativeDate(26, testStartDate, true),
        amount: 88,
        amountUnit: 'umol/L',
        duration: null,
        durationUnit: null,
        _rowVariant: 'success'
    }),
        new Dose({
            type: DoseTypes.DOSE,
            datetime: new Date(testStartDate),
            amount: 2500,
            amountUnit: 'mg',
            duration: 1.5,
            durationUnit: 'hours'
        }),
        new Dose({
            type: DoseTypes.DOSE,
            datetime: getRelativeDate(12, testStartDate, false),
            amount: 1250,
            amountUnit: 'mg',
            duration: 1.5,
            durationUnit: 'hours'
        }),
        new Dose({
            type: DoseTypes.CONC,
            datetime: getRelativeDate(4, testStartDate, false),
            amount: 24,
            amountUnit: 'mcg/mL',
            duration: null,
            durationUnit: null,
            _rowVariant: 'info'
        }),
        new Dose({
            type: DoseTypes.CONC,
            datetime: getRelativeDate(10, testStartDate, false),
            amount: 16,
            amountUnit: 'mcg/mL',
            duration: null,
            durationUnit: null,
            _rowVariant: 'info'
        })
    ]
    return {...samplePatient, doses: [...updatedDoses]}
}

// Sample using Piperacillin estimates
export const createSamplePatient2Compartment = (startDate) => {
    // set samplePatient doses to current date
    const testStartDate = startDate ?? getRelativeDate(24, null)
    const updatedDoses = [
        new Dose({
        type: DoseTypes.CLCR,
        datetime: getRelativeDate(26, testStartDate, true),
        amount: 80,
        amountUnit: 'mL/min',
        duration: null,
        durationUnit: null,
        _rowVariant: 'success'
    }),
        new Dose({
            type: DoseTypes.DOSE,
            datetime: testStartDate,
            amount: 4500,
            amountUnit: 'mg',
            duration: 20,
            durationUnit: 'minutes'
        }),
        new Dose({
            type: DoseTypes.CONC,
            datetime: getRelativeDate(4, testStartDate, false),
            amount: 24,
            amountUnit: 'mcg/mL',
            duration: null,
            durationUnit: null,
            _rowVariant: 'info'
        }),
        new Dose({
            type: DoseTypes.CONC,
            datetime: getRelativeDate(10, testStartDate, false),
            amount: 16,
            amountUnit: 'mcg/mL',
            duration: null,
            durationUnit: null,
            _rowVariant: 'info'
        })
    ]
    return {...samplePatient2Compartment, doses: [...updatedDoses]}
}

export const createTestData = (drug, drugModel, startDate, delivery, target, pathology) => {
    const testData = createSamplePatient(startDate)
    const sim = new Simulation()
    sim.simPatient = testData.patient
    sim.simDrug = new Drug(drug)
    sim.simModel = new DrugModel(drugModel)
    sim.simDoses = testData.doses
    sim.simDelivery = new Delivery(delivery)
    sim.simTarget = new Target(target)
    if (pathology) {
        sim.simPathology = new Pathogen(pathology)
    }
    const crcl = calculateCockcroftGault(sim)
    sim.generatePk(crcl)
    if (sim.hasMeasured()) {
        sim.generatePkFromMeasured()
    }
    sim.generateAdministeredDoses()
    sim.generateDosingOptions(null)
    return sim
}