import Vue from 'vue'
import Vuex from 'vuex'
import {drug_models} from "@/models/modelData/DrugModels.js";
import {calculateSince, deduplicateDoses} from "@/components/services/common";
import {DoseTypes} from "@/models/utils/constants";

Vue.use(Vuex)
const defaultState = {
    drugId: null,
    modelId: null,
    patient: null,
    delivery: null,
    target: null,
    pathology: null,
    doses: [],
    simulation: null,
    error: null,
    selectedId: null,
    loading: false,
    auth: undefined
}
export default new Vuex.Store({
    state: {...defaultState},
    mutations: {
        setDrug(state, drug) {
            state.drugId = drug?.code
        },
        setDrugId(state, drugId) {
            state.drugId = drugId
        },
        setSelectedModel(state, model) {
            state.modelId = model
        },
        setSelectedId(state, id) {
            state.selectedId = id
        },
        setPatient(state, patient) {
            state.patient = {...patient}
        },
        setDelivery(state, item) {
            state.delivery = {...item}
        },
        setTarget(state, item) {
            state.target = {...item}
        },
        setPathology(state, item) {
            state.pathology = {...item}
        },
        setDoses(state, items) {
            state.doses = [...items]
            // calculate interval since dose
            if (state.doses.length > 1) {
                state.doses = calculateSince([...state.doses])
            }
        },
        clearDoses(state) {
            state.doses = []
        },
        addDose(state, item) {
            const n1 = state.doses.length
            const n2 = item.length ?? 1
            if (item.length !== undefined) {
                const tmp = [...state.doses, ...item]
                state.doses = deduplicateDoses(tmp)
            } else {
                const tmp = {...item}
                state.doses = deduplicateDoses([...state.doses, tmp])
            }
            // pick up deduplicate
            if (state.doses.length !== n1 + n2) {
                state.error = 'This is a duplicate by type and datetime. Please try again.'
            }
            // calculate interval since dose
            if (state.doses.length > 1) {
                state.doses = calculateSince([...state.doses])
            }

        },
        removeDose(state, item) {
            state.doses = [...state.doses.filter((d) => d.id !== item.id)]
            if (state.doses.length > 1) {
                state.doses = calculateSince([...state.doses])
            }
        },
        setSimulation(state, item) {
            state.simulation = {...item}
        },
        clearSimulation(state) {
            state.simulation = null
        },
        clearDrug(state){
            state.drugId = null
            state.modelId = null
        },
        clearModel(state) {
            state.modelId = null
        },
        clearError(state) {
            state.error = null
        },
        clearAll(state) {
            Object.assign(state, defaultState)
        },
        setLoading(state, value) {
            state.loading = value
        },
        setAuth(state, member) {
            state.auth = member
        }
    },
    actions: {
        saveDrug({commit}, drug) {
            commit('setDrug', drug)
        },
        saveModel({commit}, modelId) {
            commit('setSelectedModel', modelId)
        },
        savePatient({commit}, patient) {
            commit('setPatient', patient)
        },
        saveDelivery({commit}, item) {
            commit('setDelivery', item)
        },
        saveTarget({commit}, item) {
            commit('setTarget', item)
        },
        savePathology({commit}, item) {
            commit('setPathology', item)
        },
        saveDoses({commit}, item) {
            commit('setDoses', item)
        },
        addDose({commit}, item) {
            commit('addDose', item)
        },
        removeDose({commit}, item) {
            commit('removeDose', item)
        },
        clearDoses({commit}) {
            commit('clearDoses')
        },
        saveSimulation({commit}, item) {
            commit('setSimulation', item)
            commit('setLoading', false)
        },
        clearSimulation({commit}) {
            commit('clearSimulation')
            commit('clearError')
            commit('setLoading', false)
        },
        clearModel({commit}) {
            commit('clearModel')
        },
        loadPatientData({commit}, patient) {
            if (patient.id) {
                commit('setSelectedId', patient.id)
            }
            // dervie drugId from modelId
            let drugId = null
            if (patient.modelId) {
                drug_models.forEach((d) => {
                    const found = d.models.find((i) => i.code === patient.modelId)
                    if (found) {
                        drugId = d.id
                    }
                })
            }
            commit('setDrugId', drugId)
            commit('setSelectedModel', patient.modelId)
            const drug = this.getters.getDrug
            commit('setDrug', drug)
            commit('setPatient', patient.patient)
            commit('setDelivery', patient.delivery)
            commit('setTarget', patient.target)
            commit('setPathology', patient.pathology)
            commit('setDoses', patient.doses)
        },
        clearError({commit}) {
            commit('clearError')
        },
        clearAll({commit}) {
            commit('clearAll')
        },
        saveSelectedId({commit}, id) {
            commit('setSelectedId', id)
        },
        saveLoading({commit}, value) {
            commit('setLoading', value)
        },
        login({commit}, member) {
            commit('setAuth', member)
        },
        logout({commit}) {
            commit('setAuth', undefined)
        },
    },
    getters: {
        getDrug: (state) => {
            if (state.drug) {
                return state.drug
            } else if (state.modelId) {
                return drug_models.filter((d) => {
                    return d.models.find((m) => m.code === state.modelId)
                })[0]
            }
            return null
        },
        getModel: (state) => {
            let model = null
            if (state.modelId) {
                drug_models.forEach((d) => {
                    const found = d.models.find((m) => m.code === state.modelId)
                    if (found) {
                        model = found
                    }
                })
            }
            return model
        },
        getPatient: (state) => {
            return state.patient;
        },
        getDelivery: (state) => {
            return state.delivery;
        },
        getTarget: (state) => {
            return state.target;
        },
        getPathology: (state) => {
            return state.pathology;
        },
        getDoses: (state) => {
            return state.doses;
        },
        getSimulation: (state) => {
            return state.simulation
        },
        getError: (state) => {
            return state.error
        },
        getSelectedId: (state) => {
            return state.selectedId
        },
        getLoading: (state) => {
            return state.loading
        },
        getMember: (state) => {
            return state.auth
        },
        isReady: (state) => {
            // Must have creatinine, model and patient loaded for running simulation
            // Not required to provide a dose for a simulation
            const has_creatinine = state.doses.filter((d) => {
                return (d.type === DoseTypes.SECR || d.type === DoseTypes.CLCR)
            }).length > 0
            const has_alerts = state.doses.filter((d) => d.sinceAlert).length > 0
            return state.patient && state.modelId && has_creatinine && !has_alerts
        }
    }
})
