import {Target} from "./Target";
import {Pathogen} from "./Pathogen";
import {DrugModel} from "./DrugModel";
import {Method} from "./Method";

export class Drug {
    id: string;
    name: string;
    class: string;
    target: Target[];
    pathology: Pathogen[];
    models: DrugModel[];
    methods: Method[];

    constructor(param: {
        id: string, name: string, class: string, target: Target[], pathology: Pathogen[],
        models: DrugModel[], methods: Method[]
    }) {
        this.id = param.id;
        this.name = param.name;
        this.class = param.class;
        this.target = param.target;
        this.pathology = param.pathology;
        this.models = param.models;
        this.methods = param.methods;
    }

    getAmountRange(type:string):number[] | null {
        return this.methods.filter((m: Method) => m.type === type)[0]?.amountRange
    }
    getAmountUnit(type:string):string | null {
        return this.methods.filter((m: Method) => m.type === type)[0]?.amountUnit
    }

    getIntervalRange(type:string):number[] | null {
        return this.methods.filter((m: Method) => m.type === type)[0]?.intervalRange
    }

    getIntervalUnit(type:string):string | null {
        return this.methods.filter((m: Method) => m.type === type)[0]?.intervalUnit
    }

    getBioavailability():number | undefined | null {
        return this.methods.filter((m: Method) => m.type === 'ORAL')[0]?.bioavailability
    }

    getHalflife():number | undefined | null {
        return this.methods.filter((m: Method) => m.type === 'ORAL')[0]?.halflife
    }

}