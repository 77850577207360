<template>
  <b-form-group class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" role="tab">
        <div v-b-toggle.pathology-panel>PATHOGEN</div>
      </b-card-header>
      <b-collapse id="pathology-panel" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <b-form @submit.prevent="savePathology">
              <p>Optional</p>
              <b-input-group>
                <label>Pathogen</label>
                <b-form-input
                    id="pathogen"
                    ref="micPath"
                    placeholder="Enter pathogen"
                    class=""
                    v-model="form.pathogen"
                    @input="savePathology"
                ></b-form-input>
              </b-input-group>
              <b-input-group>
                <label>MIC</label>
                <ValidationProvider name="mic" :rules="rules" vid="mic" v-slot="v">
                  <b-form-input
                      id="mic"
                      name="mic"
                      v-model="form.mic"
                      type="number"
                      :number="true"
                      class="input-with-unit"
                      placeholder="Enter MIC"
                      @input="savePathology"
                      :state="getValidationState(v)"
                      aria-describedby="input-1-live-feedback"
                      required/>
                  <b-form-invalid-feedback id="input-1-live-feedback" v-html="v.errors[0]"></b-form-invalid-feedback>
                </ValidationProvider>
                <b-input-group-append>
                  <b-form-select
                      id="micUnit"
                      ref="micUnit"
                      v-model="form.micUnit"
                      :options="units"
                      value-field="value"
                      html-field="text"
                      class="unit-dropdown"
                      :clearable="false"
                      @input="savePathology"
                      required>
                  </b-form-select>
                </b-input-group-append>
              </b-input-group>
            </b-form>
          </ValidationObserver>
        </b-card-body>
      </b-collapse>
    </b-card>
  </b-form-group>
</template>

<script>
import {displaySIUnits, units} from "@/models/utils/constants.js";
import {pathogens} from "@/models/utils/pathogens.js";

export default {
  name: "PathologyInput",
  props: ["drug", "pathologyData"],
  data() {
    return {
      form: this.initForm(),
      units: units.micConcentration.map((u) => {
        return {value: u, text: displaySIUnits(u)}
      }),
      error: null,
      rules: {
        required: true,
        micBetween: true
      }
    }
  },
  watch: {
    pathologyData() {
      this.form = this.initForm()
    }
  },
  methods: {
    displaySIUnits,
    initForm() {
      this.error = null;
      const pathology = this.pathologyData ?? null
      return {
        ...this.form,
        pathogen: pathology ? pathology.pathogen : null,
        mic: pathology ? pathology.mic : null,
        micUnit: pathology ? pathology.micUnit : 'mg/L'
      }
    },
    savePathology($event) {
      this.$refs.observer.validate().then(success => {
        if (success) {
          if (this.drug && pathogens[$event]) {
            const p = this.drug.pathology.filter((t) => t.pathogen === this.form.pathogen)[0]
            this.form.mic = p ? p.mic : pathogens[this.form.pathogen][this.drug.id]
            this.form.micUnit = p ? p.micUnit : 'mg/L'
          }
          this.$store.dispatch('savePathology', this.form);
        }
      });
    },
    getValidationState(validator) {
      return !validator.pristine && validator.validated ? validator.valid : null;
    }
  }
}
</script>

<style scoped>

</style>
