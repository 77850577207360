<template>
  <b-card>
    <div class="relative w-full">
      <canvas id="chartId" :width="width" :height="height"></canvas>
    </div>
  </b-card>
</template>

<script>

import {Chart, registerables} from 'chart.js'
import 'chartjs-adapter-moment';
import {DoseTypes} from "@/models/utils/constants";

Chart.register(...registerables)

export default {
  name: "GraphComponent",
  props: ['simdata', 'simcalculation'],
  data() {
    return {
      width: 300,
      height: 250,
      chart: null,
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day'
            },
            position: 'bottom'
          },
          y: {
            title: {
              display: true,
              text: 'mcg/mL'
            }
          }
        }
      }
    }
  },
  mounted() {
    this.loadChart()
  },
  watch: {
    simdata() {
      this.chart.destroy()
      this.loadChart()
    }
  },
  methods: {
    loadChart() {
      this.chart = new Chart("chartId", {
        type: 'scatter',
        data: this.getChartData,
        options: this.chartOptions
      })
    }
  },
  computed: {
    getChartData() {
      const sim = this.$store.getters.getSimulation
      if (sim.simDelivery.method === 'CONT') {
        return {
          datasets: [
            {
              label: 'Given',
              data: this.getAdministeredDoses,
              showLine: true
            },
            {
              label: this.getInterval,
              data: this.getData,
              showLine: true
            },
            {
              label: 'Measured',
              data: this.getMeasuredConcentrations,
              showLine: false
            },
            {
              label: 'Steady state',
              data: this.getSteadyStateLevel,
              showLine: true,
              pointRadius: 0,
              borderWidth: 1,
              borderDash: [10, 5]
            }
          ]
        }
      } else if (sim.simDelivery.method === 'ORAL') {
        return {
          datasets: [
            {
              label: 'Given',
              data: this.getAdministeredDoses,
              showLine: true
            },
            {
              label: this.getInterval,
              data: this.getData,
              showLine: true
            },
            {
              label: 'Measured',
              data: this.getMeasuredConcentrations,
              showLine: false
            },
            {
              label: 'Peak',
              data: this.getPeakLevel,
              showLine: true,
              pointRadius: 0,
              borderWidth: 1,
              borderDash: [10, 5]
            }
          ]
        }
      } else {
        return {
          datasets: [
            {
              label: 'Given',
              data: this.getAdministeredDoses,
              showLine: true
            },
            {
              label: this.getInterval,
              data: this.getData,
              showLine: true
            },
            {
              label: 'Measured',
              data: this.getMeasuredConcentrations,
              showLine: false
            },
            {
              label: 'Peak',
              data: this.getPeakLevel,
              showLine: true,
              pointRadius: 0,
              borderWidth: 1,
              borderDash: [10, 5]
            },
            {
              label: 'Trough',
              data: this.getTroughLevel,
              showLine: true,
              pointRadius: 0,
              borderWidth: 1,
              borderDash: [10, 5]
            }
          ]
        }
      }
    },
    getData() {
      let data = []
      if (this.simdata.length > 0) {
        data = this.simdata.map((d) => {
          return {x: d.time, y: d.concentration}
        })
      }
      return data
    },
    getInterval() {
      let interval = ''
      if (this.simdata.length > 0) {
        interval = this.simdata[0].interval
      }
      return interval;
    },
    getAdministeredDoses() {
      const sim = this.$store.getters.getSimulation
      return sim.simAdministeredDoses.filter((d) => d.calculation === this.simcalculation).map((d) => {
        return {x: d.time, y: d.concentration}
      })
    },
    getMeasuredConcentrations() {
      let data = []
      const sim = this.$store.getters.getSimulation
      if (sim) {
        data = sim.simDoses.filter((d) => d.type === DoseTypes.CONC).map((d) => {
          return {x: d.datetime, y: d.amount}
        })
      }
      return data
    },
    getPeakLevel() {
      const sim = this.$store.getters.getSimulation
      const peak = sim.simSelected.recommendation.peak
      return this.getData.map((d) => {
        return {x:d.x, y: peak}
      })
    },
    getTroughLevel() {
      const sim = this.$store.getters.getSimulation
      const trough = sim.simSelected.recommendation.trough
      return this.getData.map((d) => {
        return {x:d.x, y: trough}
      })
    },
    getSteadyStateLevel() {
      const sim = this.$store.getters.getSimulation
      const val = sim.simSelected.recommendation.ss
      return this.getData.map((d) => {
        return {x:d.x, y: val}
      })
    }
  }
}
</script>

<style scoped>

</style>