<template>
  <b-card id="interval-tabs"
          class="shadow rounded">
    <b-card-title>Dosing Plan Options</b-card-title>
    <b-card-body>
      <p class="text-muted">Select required plan under Action column from table below. Recommendation and display will update with selection.</p>
      <div class="form-check form-switch float-end">
        <input id="switch"
               class="form-check-input"
               type="checkbox"
               v-model="showAll"/>
        <label for="switch"
               class="form-check-label">Show All</label>
      </div>
      <b-tabs>
        <b-tab v-for="(tab, index) in tabs"
               :title="tab.title"
               :key="tab.title"
               :class='{"tab": true, "tab-bordered": true, "active": (index === selectedIndex)}'
               @click="selectTab(index)">
          <div class="tab-content">
            <div v-if="tab.title==='Custom'">
              <CustomForm :sim="sim"/>
            </div>
            <div v-else>
              <KeepAlive v-if="items.length > 0">
                <SimulationDoses :data="items" :method="sim.simDelivery.method"/>
              </KeepAlive>
              <div class="text-muted font-medium p-4" v-else>
                <p class="text-danger" v-if="sim.hasMeasured">No target plans to display - select Show All</p>
                <p v-else>
                  <span class="text-danger">No plans to display. <br/></span>
                  Add one or more measured concentrations for
                  optimised dose plans. Best results are with 2 or more concentrations (peak and trough) in one dose
                  interval.
                </p>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import SimulationDoses from "@/components/display/SimulationDoses";
import {CALCULATION} from "@/models/DoseRegime";
import CustomForm from "@/components/inputs/CustomForm.vue";

export default {
  name: "PlanOptions",
  components: {CustomForm, SimulationDoses},
  data() {
    return {
      selectedIndex: 0,
      tabs: [],
      showAll: false
    }
  },
  computed: {
    items() {
      return this.showAll ? this.loadOptions() : this.loadOptions().filter((option) => option._rowVariant)
    },
    sim() {
      return this.$store.getters.getSimulation
    }
  },
  created() {
    this.loadTabs()
  },
  methods: {
    loadTabs() {
      this.tabs = Object.keys(CALCULATION).map((d, i) => {
        return {index: i, title: CALCULATION[d]}
      })
      this.tabs.push({index: this.tabs.length, title: 'Custom'})
    },
    loadOptions() {
      if (this.tabs.length > 0 && this.sim) {
        this.selectedTab = this.tabs.filter((tab) => tab.index === this.selectedIndex)[0]
        const selectedData = this.sim.simDosePlans.filter((d) => {
          return d.recommendation.calculation.toLowerCase() === this.selectedTab.title.toLowerCase()
        })
        return selectedData.map((s) => {
          if (this.sim.simDelivery.method === 'CONT') {
            return {
              Interval: s.recommendation.interval,
              intervalUnit: 'h',
              Dose: s.recommendation.maintenance,
              doseUnit: 'mg',
              Rate: s.recommendation.duration,
              rateUnit: s.recommendation.durationUnit,
              AUC: s.recommendation.auc,
              aucUnit: "mcg*hr/mL",
              SS: s.recommendation.ss,
              ssUnit: 'mcg/mL',
              plan: s,
              _rowVariant: s._rowVariant
            }
          } else if (this.sim.simModel.getCompartment() === 2) {
            return {
              Interval: s.recommendation.interval,
              intervalUnit: 'h',
              Dose: s.recommendation.maintenance,
              doseUnit: 'mg',
              TMIC: s.recommendation.tmic,
              Peak: s.recommendation.peak,
              peakUnit: 'mcg/mL',
              plan: s,
              _rowVariant: s._rowVariant
            }
          } else {
            return {
              Interval: s.recommendation.interval,
              intervalUnit: 'h',
              Dose: s.recommendation.maintenance,
              doseUnit: 'mg',
              AUC: s.recommendation.auc,
              aucUnit: "mcg*hr/mL",
              Peak: s.recommendation.peak,
              peakUnit: 'mcg/mL',
              Trough: s.recommendation.trough,
              troughUnit: 'mcg/mL',
              plan: s,
              _rowVariant: s._rowVariant
            }
          }
        })
      }
    },
    selectTab(idx) {
      this.selectedIndex = idx;
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === this.selectedIndex;
      })
      this.loadOptions()
    }
  }
}
</script>

<style scoped>

</style>