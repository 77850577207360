// Pathogen list with default MIC values for drug - mcg/mL
export const pathogens = {
    "E.coli" : {
        "VANC": 1
    },
    "S.aureus" : {
        "VANC": 1
    },
    "MRSA": {
        "VANC": 2
    }
}