<template>
  <b-container fluid="md">
    <b-row>
      <b-col cols="2"></b-col>
      <b-col cols="8">
        <Title/>
        <router-view></router-view>
        <b-row>
          <FooterComponent/>
        </b-row>
      </b-col>
      <b-col cols="2"></b-col>
    </b-row>
  </b-container>
</template>

<script>
import Title from "@/components/Title.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  name: "LegalComponent",
  components: {Title, FooterComponent},
  methods: {
    printPage() {
      window.print()
    }
  }
}
</script>

<style scoped>

</style>