<template>
  <div v-if="drug">
    <b-card>
      <b-card-title>Model Information for {{ drug.name }}</b-card-title>
      <b-card-body>
        <b-tabs content-class="mt-3 py-4">
          <b-tab v-for="(displayItem, index) in drug.models"
                 :title="tabTitle(displayItem)"
                 :key="displayItem.code"
                 :class="tabClass(displayItem, index)"
                 :active="index === selectedIndex"
                 @click="selectTab(index)"
          >
            <div class="tab-content overflow-auto">
              <table class="table">
                <tbody>
                <tr>
                  <th>Drug model</th>
                  <td>{{ displayItem.code }}: {{ displayItem.name }}</td>
                </tr>
                <tr>
                  <th>Drug delivery methods supported</th>
                  <td>{{ displayItem.delivery.method }}</td>
                </tr>
                <tr>
                  <th>Information</th>
                  <td>
                    <ul v-for="info in displayItem.info">
                      <li>{{ info }}</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>Reference</th>
                  <td><a :href="displayItem.href">{{ displayItem.href }}</a></td>
                </tr>
                <tr>
                  <th>Population</th>
                  <td>{{ displayItem.population }}</td>
                </tr>
                <tr>
                  <th>Age range</th>
                  <td>{{ displayItem.age.range[0] }} - {{ displayItem.age.range[1] }} {{ displayItem.age.unit }}</td>
                </tr>
                <tr>
                  <th>Weight range</th>
                  <td>{{ displayItem.weight.range[0] }} - {{ displayItem.weight.range[1] }} {{displayItem.weight.unit}}
                  </td>
                </tr>
                <tr>
                  <th>Coefficients</th>
                  <td>
                    <ul>
                      <li v-for="cl in displayItem.clearance">Clearance ({{ cl.type }}): {{ cl.coeff }} +/- {{cl.sd }}
                        <span v-html="displaySIUnits(cl.unit)"></span>

                      </li>
                      <li v-for="cl in displayItem.vd">Vd ({{ cl.type }}): {{ cl.coeff }} +/- {{ cl.sd }}
                        <span v-html="displaySIUnits(cl.unit)"></span>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>Model target ranges</th>
                  <td>
                    <ul v-for="target in drug.target">
                      <li>
                        {{ DrugTargetNames[target.type] }}: {{ target.range[0] }} - {{ target.range[1] }} <span
                          v-html="displaySIUnits(target.unit)"></span>
                        &nbsp;<i>Reference: <a target="_blank" :href="target.href">
                        <b-icon-box-arrow-in-up-right/>
                      </a></i>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr v-if="displayItem.default?.loadingDoseMgPerKg">
                  <th>Dose estimates</th>
                  <td>
                    <ul>
                      <li>Loading dose: {{ displayItem.default.loadingDoseMgPerKg }} mg/kg</li>
                      <li v-if="displayItem.default.maintenanceDoseMgPerKg">Maintenance dose: {{ displayItem.default.maintenanceDoseMgPerKg }} mg/kg</li>
                    </ul>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
  <div v-else>
    <p>Select a drug model from the dropdown</p>
  </div>
</template>

<script>
import {displaySIUnits} from "@/models/utils/constants";
import {DrugTargetNames} from "../../models/utils/constants";

export default {
  name: "ModelInfo",
  props: ["model"],
  data() {
    return {
      selectedIndex: 0
    }
  },
  watch: {
    drug: {
      immediate: true,
      handler: function(newVal, oldVal) { // watch it
          this.selectedIndex = 0;
          console.log("DRUG", this.drug)
      }
    },
    model: {
      immediate: true,
      handler: function(newVal, oldVal) { // watch it

        if (newVal && this.drug){
          const newIndex = this.drug.models.indexOf(newVal)
          if (newIndex >=0){
            this.selectedIndex = newIndex;
          }
        } else if (this.drug){
          this.selectedIndex = 0;
        }
      }
    }
  },
  computed: {
    DrugTargetNames() {
      return DrugTargetNames
    },
    drug() {
      return this.$store.getters.getDrug
    }
  },
  methods: {
    displaySIUnits,
    selectTab(idx) {
      this.selectedIndex = idx;
    },
    tabTitle(displayItem){
      return displayItem.name + (displayItem.code === this.model?.code ? '*' : '');
    },
    tabClass(displayItem, index){
      return {
        "tab": true,
        "tab-bordered": true,
        "active": (index === this.selectedIndex)
      };
    }
  }
}
</script>

<style scoped>
table th {
  text-align: right;
  width: 20%;
}

table td {
  text-align: left;
}

table td ul li {
  text-align: left;
}
</style>
