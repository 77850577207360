<template>
  <b-card class="my-2 shadow rounded">
    <b-card-title><h1 class='text-md-center'>Welcome</h1></b-card-title>
    <b-card-body>
      <p>A collaborative project with the Queensland Government Metro North Herston Infectious Diseases
        Institute (HeIDI) and CSIRO to provide a
        web-based tool for precision pharmaceutical dosing for ICU patients.</p>
      <p>Based on patient's data such as the creatinine clearance rate, the tool calculates the required
        pharmacokinetic parameters to provide optimal dosing regimens for use by clinicians in the ICU.</p>
      <hr/>
      <b>Important Disclaimer</b>
      <p class="text-black-50">The results of the dosing calculator are intended to provide information for use by
        persons with clinical
        pharmaceutical training and knowledge of the
        management and interpretation of pharmaceutical dosing regimens.
        CSIRO makes the tool available on the understanding that you use it at your own risk and you exercise your own
        skill, judgement and care with respect to the suitability of the generated results for use within applicable
        therapeutic guidelines. </p>
      <p>Please refer to our
        <router-link to="terms">Terms of Use.</router-link>
      </p>
      <hr/>
      <p>To register for a free account, please click Register or Login then "Sign up now".</p>
    </b-card-body>
    <b-card-footer>
      <b-btn class="btn-lg bg-success" @click="login">Register or Login</b-btn>
    </b-card-footer>
  </b-card>
</template>

<script>
import {Member} from "@/models/Member";

export default {
  name: "WelcomeComponent",
  methods: {
    login() {
      this.$msal.login().then((res) => {
        if (res) {
          const member = new Member({
            name: res.idTokenClaims.given_name,
            email: res.username,
            oid: res.homeAccountId,
            token: res.idToken
          })
          this.$store.dispatch('login', member)
        } else {
          this.$msal.logout()
          this.$toast.error('Login failed')
        }
      }).catch((e) => {
        console.error(e)
        this.$toast.error('Login failed')
      })
    }
  }
}
</script>

<style scoped>
.input-group span {
  width: 65%;
}

.custom-select option {
  text-align: left;
}

</style>