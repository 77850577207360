import {Patient} from "@/models/Patient"

export const defaultPatient = new Patient({
    age: 0,
    ageUnit: 'years',
    height: 0,
    heightUnit: 'cm',
    weight: 0,
    weightUnit: 'kg',
    gender: null,
    critical: false,
    renal: false
})

export const samplePatient = {
    id: 'SAMPLE',
    modelId: 'BUEL2005',
    description: 'Vancomycin BUEL2005 Male 64y',
    patient : new Patient({
        id: null,
        age: 64,
        ageUnit: 'years',
        height: 180,
        heightUnit: 'cm',
        weight: 100,
        weightUnit: 'kg',
        gender: 'Male'
    }),
    delivery: {
        method: 'IV',
        interval: 12,
        intervalUnit: 'hours',
        duration: 1.5,
        durationUnit: 'hours',
        daysToDisplay: 3
    },
    target: {
        target: 'AUC/MIC',
        pdtMin: 400,
        pdtMax: 600,
        pdtUnit: 'mcg*hr/mL'
    },
    pathology: {
        pathogen: "E. coli",
        mic: 1,
        micUnit: 'mcg/mL'
    },
    doses:[] //set these at time of loading
}

export const samplePatient2Compartment = {
    id: 'SAMPLE2',
    modelId: 'PIP',
    description: 'Piperacillin UDY2015 Male 64y',
    patient : new Patient({
        id: null,
        age: 64,
        ageUnit: 'years',
        height: 180,
        heightUnit: 'cm',
        weight: 100,
        weightUnit: 'kg',
        gender: 'Male'
    }),
    delivery: {
        method: 'IV',
        interval: 6,
        intervalUnit: 'hours',
        duration: 0.3,
        durationUnit: 'hours',
        daysToDisplay: 3
    },
    target: {
        target: 'fT>MIC',
        pdtMin: 50,
        pdtMax: 100,
        pdtUnit: '%'
    },
    pathology: {
        pathogen: "E. coli",
        mic: 2,
        micUnit: 'mg/L'
    },
    doses:[] //set these at time of loading
}

