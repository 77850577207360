import {ConcTime} from "../ConcTime";
import moment from "moment/moment";
import {Dose} from "../Dose";
import {getRelativeDate} from "../../components/services/common";
import {runBayesianFit, runBayesianFit2} from "../../plugins/bayes";
import {PkParam} from "../PkParam";
import {Delivery} from "../Delivery";

import {DEBUG} from "../../main"
// Title: Calculations for two compartment models
// Ref: https://pharmacy.ufl.edu/files/2013/01/two-compartment-model.pdf
// Using linear elimination with two phases: distribution and elimination
// **It is not clear which equations to use reliably.  It is likely that different models may require different equations.**

// Beta param for two component
// @params Q : intercompartmental clearance (L/h)
// @params vd1: Vd central (L)
// @params vd2: Vd peripheral (L)
// @params cl: drug clearance (L/h)
// http://jupiter.chem.uoa.gr/thanost/papers/papers8/PFIM_PKPD_library.pdf
export const calculateBeta = (Q: number, vd1: number, vd2: number, cl: number) => {
    const cpt1 = Q / vd1
    const cpt2 = Q / vd2
    const K = cl / vd1
    return 0.5 * (cpt1 + cpt2 + K - Math.sqrt(((cpt1 + cpt2 + K) * (cpt1 + cpt2 + K)) - 4 * (cpt2 * K)))
}

// Alpha param for two component
// @params Q : intercompartmental clearance (L/h)
// @params vd1: Vd central (L)
// @params vd2: Vd peripheral (L)
// @params cl: drug clearance (L/h)
// @params beta: beta slope
// http://jupiter.chem.uoa.gr/thanost/papers/papers8/PFIM_PKPD_library.pdf
export const calculateAlpha = (Q: number, vd1: number, vd2: number, cl: number, beta: number) => {
    const cpt2 = Q / vd2
    const K = cl / vd1
    return (cpt2 * K) / beta
}
// Compartment (C0)
// @params type: string - must be A or B
// @params Q : intercompartmental clearance (L/h)
// @params vd1: Vd central (L)
// @params vd2: Vd peripheral (L)
// @params alpha: alpha slope
// @params beta: beta slope
// @return value (mg/L)
// http://jupiter.chem.uoa.gr/thanost/papers/papers8/PFIM_PKPD_library.pdf #1.2.2.2
export const calculateCompartment = (type: string, Q: number, vd1: number, vd2: number, alpha: number, beta: number) => {
    const cpt2 = Q / vd2
    return type === 'A' ? (1 / vd1) * ((alpha - cpt2) / (alpha - beta)) :
        type === 'B' ? (1 / vd1) * ((beta - cpt2) / (beta - alpha)) :
            null
}

// Steady state concentration for 2 compartment model
// @params dose: dose amount (mg)
// @params A: A intercept
// @params B: B intercept
// @params alpha: alpha slope
// @params beta: beta slope
// @params interval: interval between doses (h)
// @params infusion: duration of infusion (h)
// @return mg/L (mcg/ml)
// http://jupiter.chem.uoa.gr/thanost/papers/papers8/PFIM_PKPD_library.pdf #1.2.2.2
export const calculateSteadyState2 = (dose: number, A: number, alpha: number, B: number, beta: number, interval: number, infusion: number) => {
    const t = interval - infusion
    const cA = A / alpha * (((1 - Math.exp(-alpha * infusion)) * Math.exp(-alpha * t)) / (1 - Math.exp(-alpha * interval)))
    const cB = B / beta * (((1 - Math.exp(-beta * infusion)) * Math.exp(-beta * t)) / (1 - Math.exp(-beta * interval)))
    return (dose / infusion) * (cA + cB)
}

// Plasma concentration at time with 2 compartment
// Ref: https://pharmacy.ufl.edu/files/2013/01/5127-28-equations.pdf
// @params c0: initial or starting concentration (mcg/ml)
// @params dose: dose amount (mg)
// @params t: time of required concentration in hrs from end of infusion (ie delta time)
// @params infusion: duration of infusion (h)
// @params A: A intercept
// @params B: B intercept
// @params alpha: alpha slope
// @params beta: beta slope
// @returns concentration (mcg/ml)
export const calculateConcentration2 = (c0: number, dose: number, t: number, infusion: number, A: number, B: number, alpha: number, beta: number) => {
    const Aphase = (A / alpha) * (1 - Math.exp(-alpha * infusion)) * Math.exp(-alpha * t)
    const Bphase = (B / beta) * (1 - Math.exp(-beta * infusion)) * Math.exp(-beta * t)
    return (c0 + (dose / infusion)) * (Aphase + Bphase)
}

// Time over MIC
// for a single dose
// @params cPeak: peak concentration (mg/L)
// @params cMic: MIC concentration (mg/L)
// @params infusion: duration of infusion (h)
// @returns time (h)
export const calculateTmic2 = (cPeak: number, cMic: number, alpha: number, beta: number, infusion: number) => {
    let tMic = 0
    if (cMic > 0) {
        // t_infusion from MIC
        let t_inf_mic = 0
        if (infusion > 0) {
            const c = Math.sqrt(infusion * infusion + cPeak * cPeak)
            const sinA = (infusion * Math.sin(90)) / c
            const tmic = (sinA * sinA * cMic) / (infusion * cPeak)
            t_inf_mic = infusion - tmic
        }
        // Decay from Peak to MIC + infusion from MIC
        tMic = (Math.log(cPeak / cMic)) + t_inf_mic //TODO how to calculate with decay?
    }
    return tMic
}

// Calculate concentration time series
// @params interval : interval between doses (hr)
// @params label: chart legend
// @params dose: amount of dose (mg)
// @params initialTime : datetime at which initialConcentration is recorded (or estimated), if c0 > 0, includes infusion time
// @params initialConcentration: Initial concentration (mcg/ml)
// @params infusionDuration infusion duration
// @params infusionUnit: unit for infusion duration (eg hours)
// @params A: A compartment intercept
// @params B: B compartment intercept
// @params alpha: alpha slope
// @params beta: beta slope
// @params calculation: Population or Patient
// @returns array of ConcTime objects for plotting
export const calculateConcTimeseries2 = (interval: number, label: string, dose: number,
                                         initialTime: Date, initialConcentration: number,
                                         infusionDuration: number | null, infusionDurationUnit: string,
                                         A: number, B: number, alpha: number, beta: number,
                                         calculation: string): ConcTime[] => {
    const doseArray: ConcTime[] = []
    let c0: number = initialConcentration
    let concentration: number = initialConcentration
    const infusion: number = infusionDuration ?? 1
    const infusionHrs = infusionDurationUnit === "hours" ? infusion : infusion / 60
    let time = new Date(initialTime)
    // initial point
    const d = new ConcTime({
        interval: label,
        time: new Date(time),
        concentration,
        concentrationUnit: 'mcg/mL',
        calculation
    })
    doseArray.push(d)
    if (DEBUG) {
        console.log(`${label} initial 0. t=${time}  concentration=${concentration}`)
    }
    // if first dose, end of infusion - peak
    if (initialConcentration === 0) {
        concentration = calculateConcentration2(initialConcentration, dose, infusionHrs, infusionHrs, A, B, alpha, beta)
        // @ts-ignore
        time = moment(time).add(infusion, infusionDurationUnit).toDate()
        const d = new ConcTime({
            interval: label,
            time: new Date(time),
            concentration,
            concentrationUnit: 'mcg/mL',
            calculation
        })
        doseArray.push(d)

        if (DEBUG) {
            console.log(`${label} loading 1. t=${time}  concentration=${concentration}`)
        }
    }

    if (interval > 0) {
        let intervalStart: number = 1

        // 1 day for generated or one cycle for administered
        const x = label === 'administered' ? 1 : 24 / interval * 1
        // over x cycles, at each interval, increment dose
        for (let i: number = 0; i < x; i++) {
            const t_interval = interval - infusionHrs
            // @ts-ignore
            const nextDoseTime = moment(time).add(t_interval, "hours").toDate()
            if (DEBUG > 1) {
                console.log(`nextDoseTime: ${nextDoseTime} interval-inf: ${t_interval} time: ${time}`)
            }
            // calculate dose per hour
            for (let t: number = intervalStart; t < t_interval; t++) {
                time = moment(time).add(1, 'hours').toDate()
                concentration = calculateConcentration2(concentration, dose, t, infusionHrs, A, B, alpha, beta)
                if (DEBUG > 1) {
                    console.log(`${label} Dose ${i} ${time}. concentration=${concentration}`)
                }

                const d = new ConcTime({
                    interval: label,
                    time: new Date(time),
                    concentration,
                    concentrationUnit: 'mcg/mL',
                    calculation
                })
                doseArray.push(d)
            }
            // Add trough dose
            // @ts-ignore
            const dt: number = Math.abs(nextDoseTime - time) / (1000 * 60 * 60)
            if (dt > 0) {
                concentration = calculateConcentration2(concentration, dose, dt, infusionHrs, A, B, alpha, beta)
                const d = new ConcTime({
                    interval: label,
                    time: new Date(nextDoseTime.getTime()),
                    concentration,
                    concentrationUnit: 'mcg/mL',
                    calculation
                })
                doseArray.push(d)
                if (DEBUG > 1) {
                    console.log(`${label} Trough dose diff ${i}: diff=${dt} t0=${d.time} c=${d.concentration}`)
                }
            }
        }
    }
    if (DEBUG) {
        console.log(`${label} added ${doseArray.length} doses for ${calculation}`)
    }
    return doseArray
}

export const calculatePkByBayesian2 = (pop_vc: PkParam, pop_vp: PkParam, pop_cl: number, pop_q: number, simDoses: Dose[]) => {
    if (pop_vc && pop_vp) {
        const pop_params = {
            vc: (typeof pop_vc.value === "string") ? parseFloat(pop_vc.value) : pop_vc.value,
            vc_sd: (typeof pop_vc.sd === "string") ? parseFloat(pop_vc.sd) : pop_vc.sd,
            vp: (typeof pop_vp.value === "string") ? parseFloat(pop_vp.value) : pop_vp.value,
            vp_sd: (typeof pop_vp.sd === "string") ? parseFloat(pop_vp.sd) : pop_vp.sd,
            q: pop_q,
            cl: pop_cl
        }
        // @ts-ignore
        const doses = simDoses.sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
        const pkparams = runBayesianFit2(pop_params, doses)
        if (DEBUG) {
            console.log(pkparams)
        }
        return {
            vc: pkparams.vc_est,
            vp: pkparams.vp_est,
            A: pkparams.a_est,
            alpha: pkparams.alpha,
            B: pkparams.b_est,
            beta: pkparams.beta
        }
    }
    return null
}

