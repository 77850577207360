import {CALCULATION} from "./DoseRegime";

export class ConcTime {
    constructor(param: {
        interval: string,
        time: Date,
        concentration: number,
        concentrationUnit: string,
        calculation?: string | null
    }) {
        this.interval = param.interval;
        this.time = param.time;
        this.concentration = param.concentration;
        this.concentrationUnit = param.concentrationUnit;
        if (param.calculation) {
            this.calculation = param.calculation;
        }
    }

    interval: string;
    time: Date;
    concentration: number;
    concentrationUnit: string;
    calculation: string | null = CALCULATION.POPULATION;
}

