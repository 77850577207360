<template>
  <div>
    <b-card>
      <b-card-title>Saved Patients</b-card-title>
      <b-card-body>
        <b-table
            id="fb-table"
            striped
            small
            responsive="sm"
            @row-clicked="showItem"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            label-sort-asc=""
            label-sort-desc=""
            label-sort-clear="">
          <template #cell(actions)="{item}">
            <span class="icon-span">
              <b-icon-trash width="1.6em" height="1.6em" title="Delete"
                            @click="deleteFromIDB(item)"></b-icon-trash>
            </span>
          </template>
        </b-table>
        <KeepAlive>
          <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              aria-controls="fb-table"
          ></b-pagination>
        </KeepAlive>
      </b-card-body>
    </b-card>
    <b-card v-if="displayItem">
      <b-card-body>
        <table id="details" class="table">
          <tbody>
          <tr>
            <th>Description</th>
            <td>{{ displayItem.description }}</td>
          </tr>
          <tr>
            <th>Drug model</th>
            <td>{{ displayItem.modelId }}</td>
          </tr>
          <tr>
            <th>Age</th>
            <td>{{ displayItem.patient.age }} {{ displayItem.patient.ageUnit }}</td>
          </tr>
          <tr>
            <th>Weight</th>
            <td>{{ displayItem.patient.weight }} {{ displayItem.patient.weightUnit }}</td>
          </tr>
          <tr>
            <th>Height</th>
            <td>{{ displayItem.patient.height }} {{ displayItem.patient.heightUnit }}</td>
          </tr>
          <tr>
            <th>Gender</th>
            <td>{{ displayItem.patient.gender }}</td>
          </tr>
          <tr>
            <th>Doses</th>
            <td>
              <b-badge class="mx-2 p-2">SeCr: {{
                  displayItem.doses.filter((d) => d.type === DoseTypes.SECR).length
                }}
              </b-badge>
              <b-badge class="mx-2 p-2">Given: {{
                  displayItem.doses.filter((d) => d.type === DoseTypes.DOSE).length
                }}
              </b-badge>
              <b-badge class="mx-2 p-2">Measured: {{
                  displayItem.doses.filter((d) => d.type === DoseTypes.CONC).length
                }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th>Target</th>
            <td>{{ displayItem.target.target }}: {{ displayItem.target.pdtMin }} - {{ displayItem.target.pdtMax }} <span
                v-html="displaySIUnits(displayItem.target.pdtUnit)"></span></td>
          </tr>
          <tr>
            <th>Pathology</th>
            <td>{{ displayItem.pathology.pathogen }} <span
                v-if="displayItem.pathology?.mic">(MIC:{{ displayItem.pathology.mic }} {{
                displayItem.pathology.micUnit
              }})</span></td>
          </tr>
          </tbody>
        </table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import IDB from "@/components/services/vidb";
import {displaySIUnits, DoseTypes} from "@/models/utils/constants";

export default {
  name: "SavedData",
  computed: {
    DoseTypes() {
      return DoseTypes
    }
  },
  props: ['update'],
  data() {
    return {
      sortBy: 'id',
      sortDesc: false,
      perPage: 10,
      currentPage: 1,
      items: [],
      displayItem: null,
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
          tdClass: 'text-md-start'
        },
        {
          key: 'patient.id',
          label: 'Patient ID',
          sortable: true,
          tdClass: 'text-md-start'
        },
        {
          key: 'description',
          label: 'Description',
          sortable: true,
          tdClass: 'text-md-start'
        },
        {
          key: 'actions',
          sortable: false
        }
      ]
    }
  },
  created() {
    this.getSavedItems()
  },
  watch: {
    update() {
      this.getSavedItems()
    }
  },
  methods: {
    displaySIUnits,
    getSavedItems() {
      IDB.getAll().then((items) => this.items = items)
    },
    deleteFromIDB(item) {
      IDB.remove(item.id)
      this.getSavedItems()
    },
    showItem(item) {
      this.displayItem = item
    }
  }
}
</script>

<style scoped>

#details th {
  text-align: right;
  width: 20%;
}

#details td {
  text-align: left;
}
</style>