<template>
  <b-form-group class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" role="tab">
        <div v-b-toggle.patient-panel>PATIENT</div>
      </b-card-header>
      <b-collapse id="patient-panel" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form @submit.prevent="savePatient">
              <b-input-group>
                <label>Gender</label>
                <v-select
                    id="gender"
                    placeholder="Select gender"
                    v-model="form.gender"
                    class="w-75"
                    @input="savePatient"
                    :options="genderList"
                    :clearable="false"
                    aria-required="true"
                ></v-select>
              </b-input-group>
              <b-input-group>
                <label>Age</label>
                <ValidationProvider vid="age" name="Age" :rules="rules.age" v-slot="v">
                  <b-form-input
                      id="age"
                      name="age"
                      v-model="form.age"
                      type="number"
                      :number="true"
                      placeholder="Enter age"
                      @input="savePatient"
                      :state="getValidationState(v)"
                      aria-describedby="input-1-live-feedback"
                      required/>
                  <b-form-invalid-feedback id="input-1-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
                <b-input-group-append>
                  <ValidationProvider vid="ageUnit">
                    <b-form-select
                        id="ageUnit" ref="ageUnit"
                        class="unit-dropdown"
                        v-model="form.ageUnit"
                        :options="units.age"
                        :clearable="false"
                        @input="savePatient"
                        required>
                    </b-form-select>
                  </ValidationProvider>
                </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <label>Height</label>
                <ValidationProvider vid="height" name="Height" :rules="rules.height" v-slot="v">
                  <b-form-input
                      id="height"
                      name="height"
                      v-model="form.height"
                      type="number"
                      :number="true"
                      class="input-with-unit"
                      placeholder="Enter height"
                      @input="savePatient"
                      :state="getValidationState(v)"
                      aria-describedby="input-2-live-feedback"
                      required/>
                  <b-form-invalid-feedback id="input-2-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
                <b-input-group-append>
                  <ValidationProvider vid="heightUnit">
                    <b-form-select
                        id="heightUnit" ref="heightUnit"
                        v-model="form.heightUnit"
                        class="unit-dropdown"
                        placeholder="Unit"
                        @input="savePatient"
                        :options="units.height"
                        :clearable="false"
                        required>
                    </b-form-select>
                  </ValidationProvider>
                </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <label>Weight</label>
                <ValidationProvider vid="weight" name="Weight" :rules="rules.weight" v-slot="v">
                  <b-form-input
                      id="weight"
                      name="weight"
                      v-model="form.weight"
                      type="number"
                      :number="true"
                      class="input-with-unit"
                      @input="savePatient"
                      :state="getValidationState(v)"
                      aria-describedby="input-3-live-feedback"
                      placeholder="Enter weight"
                      required/>
                  <b-form-invalid-feedback id="input-3-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
                </ValidationProvider>
                <b-input-group-append>
                  <ValidationProvider vid="weightUnit">
                    <b-form-select
                        id="weightUnit" ref="weightUnit"
                        v-model="form.weightUnit"
                        class="unit-dropdown"
                        @input="savePatient"
                        :options="units.weight"
                        :clearable="false"
                        required>
                    </b-form-select>
                  </ValidationProvider>
                </b-input-group-append>
              </b-input-group>
              <b-input-group>
                <label >BMI</label>
                <b-form-input
                    id="bmi"
                    :value="bmi"
                    type="number"
                    :number="true"
                    class="calculated"
                    readonly
                    disabled
                />
                <div class="input-group-append">&nbsp;</div>
              </b-input-group>
            </form>
          </ValidationObserver>
        </b-card-body>
      </b-collapse>
    </b-card>
  </b-form-group>
</template>

<script>
import {genders, units} from "@/models/utils/constants.js";
import {Patient} from "@/models/Patient";
import {defaultPatient} from "@/models/utils/defaults";
import {drug_models} from "@/models/modelData/DrugModels";
import "@/models/utils/validations"

export default {
  name: "PatientInput",
  data() {
    return {
      form: this.initForm(),
      genderList: genders,
      units: units,
      error: null,
      rules:
          {
            age: {
              required: true,
              ageBetween: ({
                unit: '@ageUnit',
                model: this.$store.getters.getModel
              })
            },
            weight: {
              required: true,
              weightBetween: ({
                unit: '@weightUnit',
                model: this.$store.getters.getModel
              })
            },
            height: {
              required: true,
              heightBetween: ({
                unit: '@heightUnit',
                model: this.$store.getters.getModel
              })
            }
          }
    }
  },
  props: ["patientData"],
  watch: {
    patientData() {
      this.form = this.initForm()
    }
  },
  computed: {
    bmi() {
      return this.form.bmi()
    }
  },
  methods: {
    initForm() {
      return this.patientData ? new Patient(this.patientData) : defaultPatient
    },
    savePatient() {
      this.$refs.observer.validate().then(success => {
        if (success) {
          this.$store.dispatch('savePatient', this.form);
        }
      });
    },
    getValidationState(validator) {
      return !validator.pristine && validator.validated ? validator.valid : null;
    }
  }
}
</script>

<style scoped>

</style>
