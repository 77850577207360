<template>
  <div>
    <DateTimeInput :field="name" @datetimeEvent="addDateTimeValue"/>
    <b-input-group class="justify-between">
      <label>{{ label }}</label>
      <ValidationProvider :name="name" :vid="name" :rules="rules" v-slot="v">
        <b-form-input
            :id="name"
            :name="name"
            v-model="form"
            :state="getValidationState(v)"
            aria-describedby="input-1-live-feedback"
            type="number"
            :number="true"
            class="input-with-unit"
            :placeholder="placeholder"
            @input="saveData"
            required/>
        <b-form-invalid-feedback id="input-1-live-feedback">{{ v.errors[0] }}</b-form-invalid-feedback>
      </ValidationProvider>
      <b-input-group-append class="ml-2">
        <ValidationProvider vid="crUnit">
          <b-form-select ref="crUnit"
                         id="crUnit"
                         v-model="unit"
                         html-field="text"
                         value-field="value"
                         class="unit-dropdown"
                         :options="units"
                         :clearable="false"
                         @input="saveData"
                         required>
          </b-form-select>
        </ValidationProvider>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
//* Input with date, validation and units */
import DateTimeInput from "@/components/inputs/DateTimeInput.vue";
import {displaySIUnits} from "@/models/utils/constants";

export default {
  name: "InputDateValidation",
  components: {DateTimeInput},
  props: {
    fieldDate: null,
    fieldName: null,
    fieldLabel: null,
    fieldPlaceholder: '',
    fieldForm: null,
    fieldUnit: null,
    unitList: null,
    rulesList: null
  },
  data() {
    return {
      datetime: this.fieldDate,
      name: this.fieldName,
      label: this.fieldLabel,
      placeholder: this.fieldPlaceholder,
      form: this.fieldForm,
      unit: this.fieldUnit,
      units: this.unitList.map((u) => {
        return {value: u, text: displaySIUnits(u)}
      }),
      rules: this.rulesList
    }
  },
  computed: {
    error() {
      return this.$store.getters.getError
    },
    isFormValid() {
      return this.datetime !== null && this.fieldForm !== null;
    }
  },
  methods: {
    saveData() {
      this.$emit('saveInputData', {datetime: this.datetime, value: this.form, unit: this.unit})
    },
    getValidationState(validator) {
      return !validator.pristine && validator.validated ? validator.valid : null;
    },
    addDateTimeValue(event) {
      if (event.field === this.fieldName) {
        this.datetime = event.value;
        this.saveData();
      }
    },
  }
}
</script>

<style scoped>

</style>