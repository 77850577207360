export class PkParam {
    key: string | null | undefined;
    label: string | null | undefined;
    value: string | number | null | undefined;
    sd?: string | number | null | undefined;
    unit: string | null | undefined;
    type: string | undefined

    constructor(param: {
        key: string | null | undefined,
        label: string | null | undefined,
        value: string | number | null | undefined,
        sd?: string | number | null | undefined,
        unit: string | null | undefined,
        type: string | undefined
    }) {
        this.key = param.key;
        this.label = param.label;
        this.value = param.value;
        this.sd = param.sd;
        this.unit = param.unit;
        this.type = param.type;
    }
}