import axios from "axios";
import {API_URL, JWT_CODE} from "@/main";

const authHeaders = (token) => {
    return {
        withCredentials: false,
        headers: {
            "Accept": "*/*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        }
    };
}
export const EmailService = {
    registerUser: function (form) {
        const apiUrl = API_URL
        const body = {"name": form.name, "email": form.email, "organization": form.organization}
        const url = `${apiUrl}/register/`;
        if (JWT_CODE.length > 10) {
            const url_token = `${apiUrl}/token/?code=${JWT_CODE}`
            return axios.get(url_token).then((response) => {
                if (response.status === 200 && response.data.access_token) {
                    const authHeader = authHeaders(response.data.access_token)
                    return axios.post(url, body, authHeader);
                }
            })
        }
    },
    checkAPI: function() {
        return axios.get(API_URL)
    }
}