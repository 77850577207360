<template>
  <b-table id="pk-table"
           striped
           fixed
           small
           responsive="sm"
           :items="items"
           :fields="fields"
  >
    <template #cell(value)="{item}">
      {{ item.value }} <span v-if="item.sd">&plusmn; {{ item.sd }}</span>
    </template>
    <template #cell(unit)="{item}"><span v-html="displaySIUnits(item.unit)"></span></template>
  </b-table>
</template>

<script>
import {displaySIUnits} from "@/models/utils/constants";

export default {
  name: "PkParams",
  methods: {displaySIUnits},
  data() {
    return {
      fields: [
        {
          key: 'label',
          sortable: true
        },
        {
          key: 'type',
          sortable: true
        },
        {
          key: 'value',
          sortable: true
        },
        {
          key: 'unit',
          sortable: true
        }
      ]
    }
  },
  computed: {
    items() {
      const sim = this.$store.getters.getSimulation
      return sim ? sim.pkParams : []
    }
  }
}
</script>

<style scoped>

</style>