import {extend} from "vee-validate";
import {between, required} from "vee-validate/dist/rules";
import {displaySIUnits} from "./constants";

// References:
// creatinine: https://emedicine.medscape.com/article/2054342-overview?reg=1
export const validations = {
    "creatinine": {
        "mg/dL": {
            min: 0.1,
            max: 5
        },
        "umol/L": {
            min: 10,
            // max: 442 - Changed to 999 as requested by UQ
            max: 999
        },
        "mL/min": {
            min: 1,
            max: 100
        },
        "L/h": {
            min: 1,
            max: 100
        }
    },
    "age": {
        "years": [1, 99],
        "days": [0, 365]
    },
    "weight": {
        "kg": [30, 150],
        "lb": [60, 330]
    },
    "height": {
        "cm": [100, 250],
        "in": [30, 100]
    },
    "mic": [0.1, 100],
    "interval": [4, 24],
    "duration": {
        "hours": [0.01, 24], "minutes": [0.1, 60]
    }
}

extend('required', {
    ...required,
    message: 'This field is required'
})

extend('ageBetween', {
    computesRequired: false,
    params: ['unit', 'model'],
    validate: (value, {unit, model}) => {
        const check = model.age && unit === model.age.unit ? model.age.range : validations.age[unit]
        const valid = check[0] <= parseFloat(value) && parseFloat(value) <= check[1];
        return new Promise(resolve => {
            resolve({
                valid: valid,
                data: valid ? undefined : {message: `Age really must be between ${check[0]} and ${check[1]} ${model.age.unit}`}
            });
        });
    },
    message: (field, params) => {
        const check = params.model.age?.range ?? validations.age[params._unit_]
        const min = check[0];
        const max = check[1];
        return `Age must be between ${min} and ${max} ${params.model.age.unit}`;
    },
});

extend('weightBetween', {
    computesRequired: false,
    params: ['unit', 'model'],
    validate: (value, {unit, model}) => {
        const check = model.weight && unit === model.weight.unit ? model.weight.range : validations.weight[unit]
        const valid = check[0] <= parseFloat(value) && parseFloat(value) <= check[1];
        const checkUnit = model.weight ? model.weight.unit : unit
        return new Promise(resolve => {
            resolve({
                valid: valid,
                data: valid ? undefined : {message: `Weight must be between ${check[0]} and ${check[1]} ${checkUnit}`}
            });
        });
    },
    message: (field, params) => {
        const check = params.model.weight?.range ?? validations.weight[params._unit_]
        const checkUnit = params.model.weight ? params.model.weight.unit : params._unit_
        const min = check[0];
        const max = check[1];
        return `Weight must be between ${min} and ${max} ${checkUnit}`;
    },
});

extend('heightBetween', {
    computesRequired: false,
    params: ['unit', 'model'],
    validate: (value, {unit, model}) => {
        const check = model.height && unit === model.height.unit ? model.height.range : validations.height[unit]
        const valid = check[0] <= parseFloat(value) && parseFloat(value) <= check[1];
        return new Promise(resolve => {
            resolve({
                valid: valid,
                data: valid ? undefined : {message: `Height really must be between ${check[0]} and ${check[1]} ${unit}`}
            });
        });
    },
    message: (field, params) => {
        const check = params.model.height?.range ?? validations.height[params._unit_]
        const checkUnit = params.model.height ? params.model.height.unit : params._unit_
        const min = check[0];
        const max = check[1];
        return `Height must be between ${min} and ${max} ${checkUnit}`;
    },
});

extend('secrRequired', {
    ...required,
    message: 'SeCr is required for optimized dosing.'
});

extend('secrBetween', {
    computesRequired: false,
    params: ['unit'],
    validate: (value, {unit}) => {
        const v = validations["creatinine"][unit]
        const valid = v.min <= parseFloat(value) && parseFloat(value) <= v.max
        return new Promise(resolve => {
            resolve({
                valid: valid,
                data: valid ? undefined : {message: `Serum creatinine must be between ${v.min} and ${v.max}`}
            });
        });
    },
    message: (field, params) => {
        const v = validations["creatinine"][params._unit_]
        return `SeCr must be between ${v.min} and ${v.max} ${params._unit_}`;
    },
});

extend('micBetween', {
    computesRequired: false,
    validate: (value) => {
        const v = validations["mic"]
        const valid = v[0] <= parseFloat(value) && parseFloat(value) <= v[1]
        return new Promise(resolve => {
            resolve({
                valid: valid,
                data: valid ? undefined : {message: `MIC must be between ${v[0]} and ${v[1]}`}
            });
        });
    },
    message: (field, params) => {
        const v = validations["mic"]
        return `MIC must be between ${v[0]} and ${v[1]}`;
    },
});

extend('targetBetween', {
    computesRequired: false,
    params: ['method', 'unit', 'minValue', 'maxValue', 'drug'],
    validate: (value, {method, unit, minValue, maxValue, drug}) => {
        const v = drug.target.filter((t) => t.type === method)[0]
        const min = v ? v.range[0] : 1
        const max = v ? v.range[1] : 100
        let valid = parseFloat(minValue) < parseFloat(maxValue)
        let message = 'Minimum must be less than maximum value'
        if (valid) {
            valid = min <= parseFloat(value) && parseFloat(value) <= max
            message = `Target must be between ${min} and ${max} ${unit}`
        }
        return new Promise(resolve => {
            resolve({
                valid,
                data: valid ? undefined : {message}
            });
        });
    },
    message: (field, params, data) => {
        const v = params.drug.target.filter((t) => t.type === params._method_)[0]
        if (v) {
            const min = v.range[0]
            const max = v.range[1]
            return parseFloat(params._minValue_) < parseFloat(params._maxValue_) ?
                `Target must be between ${min} and ${max} ${params._unit_}`
                : 'Minimum must be less than maximum value';
        }
        return ''
    },
});

extend('intervalBetween', {
    validate: (value) => {
        const v = validations["interval"]
        return v[0] <= parseFloat(value) && parseFloat(value) <= v[1]
    },
    message: (field, params) => {
        const v = validations["interval"]
        return `Interval must be between ${v[0]} and ${v[1]} hours`;
    },
});

extend('intervalDuration', {
    computesRequired: false,
    params: ['duration', 'durationUnit', 'intervalUnit'],
    validate: (value, {duration, durationUnit, intervalUnit}) => {
        const i = (intervalUnit === 'hours') ? value * 1 : value * 60
        const d = (durationUnit === 'hours') ? duration * 1 : duration * 60
        return d < parseFloat(i)
    },
    message: (field, params) => {
        return `Interval must be greater than duration`;
    },
});

extend('durationBetween', {
    computesRequired: false,
    params: ['unit'],
    validate: (value, {unit}) => {
        const v = validations["duration"]
        const valid = v[unit][0] <= parseFloat(value) && parseFloat(value) <= v[unit][1]
        return new Promise(resolve => {
            resolve({
                valid: valid,
                data: valid ? undefined : {message: `Duration must be between ${v[unit][0]} and ${v[unit][1]} ${unit}`}
            });
        });
    },
    message: (field, params) => {
        const v = validations["duration"]
        return `Duration must be between ${v[params._unit_][0]} and ${v[params._unit_][1]} ${params._unit_}`;
    },
});

extend('doseBetween', {
    computesRequired: false,
    params: ['unit', 'model'],
    validate: (value, {unit, model}) => {
        const convert = unit === 'g' ? 1000 : 1
        const min = model.default.minDose / convert
        const max = model.default.maxDose / convert
        return min <= parseFloat(value) && parseFloat(value) <= max;
    },
    message: (field, params, data) => {
        const convert = params._unit_ === 'g' ? 1000 : 1
        const min = params.model.default.minDose / convert
        const max = params.model.default.maxDose / convert
        return `Dose must be between ${min} and ${max} ${params._unit_}`
    },
});

extend('between', {
    ...between,
    message: (field, params) => {
        return `${field} must be between ${params.min} and ${params.max}`
    }
});