export class Delivery {
    method: string;
    interval: number;
    intervalUnit: string;
    duration: number;
    durationUnit: string;
    daysToDisplay: number;

    constructor(param: { method: string, interval: number, intervalUnit: string, duration: number, durationUnit: string, daysToDisplay: number }) {
        this.method = param.method;
        this.interval = param.interval;
        this.intervalUnit = param.intervalUnit;
        this.duration = param.duration;
        this.durationUnit = param.durationUnit;
        this.daysToDisplay = param.daysToDisplay;
    }
}